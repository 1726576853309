import Gauge from "../charts/Gauge";
import { IconInfoWinLose } from "../Icons";
import CardMetric from "./CardMetric";


const WinRateCard = ({ percent }) => {
  return (
    <CardMetric title="Win Rate" extra={<IconInfoWinLose />}>
      <Gauge width={'100%'} height={100} percent={percent} name={'Win Rate'} />
    </CardMetric>
  )
}

export default WinRateCard;