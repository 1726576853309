import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { addBlogPost } from "../../services/blog.service";
import PageTitle1 from "../../components/PageTitle1";

const { Option } = Select;


// PARAMETERS //


const AddBlogPost = () => {

    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0()

    const onFinish = async (values) => {
        setLoading(true);

        const accessToken = await getAccessTokenSilently();
        await addBlogPost(accessToken, values)

        setLoading(false);
    }

    return (<>
        <PageTitle1>• Add Blog Post</PageTitle1>

        <Card className='card'>
            <Form onFinish={onFinish} layout="vertical" initialValues={{ status: "NEW" }}>
                <Form.Item
                    label="Path"
                    name="path"
                    rules={[{ required: true, message: "Please enter a path" }]}
                >
                    <Input placeholder="path-of-the-blog" />
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Please enter a valid title" }]}
                >
                    <Input placeholder="Enter a title" />
                </Form.Item>

                <Form.Item
                    label="Meta Description"
                    name="metaDescription"
                    rules={[{ required: true, message: "Please enter a meta description" }]}
                >
                    <Input placeholder="Enter meta description" />
                </Form.Item>

                <Form.Item
                    label="Meta Keywords"
                    name="metaKeywords"
                    rules={[{ required: true, message: "Please enter a meta keywords" }]}
                >
                    <Input placeholder="Enter meta keywords" />
                </Form.Item>

                <Form.Item
                    label="Image Description"
                    name="imgAlt"
                    rules={[{ required: true, message: "Please enter a image description" }]}
                >
                    <Input placeholder="Enter image description" />
                </Form.Item>

                <Form.Item
                    label="Post Content"
                    name="content"
                    rules={[{ required: true, message: "Please enter the post content" }]}
                >
                    <Input.TextArea rows={4} placeholder="Enter the post content" />
                </Form.Item>

                <Form.Item
                    label="CTA"
                    name="cta"
                    rules={[{ required: true, message: "Please enter a cta text" }]}
                >
                    <Input placeholder="Enter cta text" />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="status"
                >
                    <Select placeholder="Status">
                        <Option value="NEW">NEW</Option>
                        <Option value="PUBLISHED">PUBLISHED</Option>
                        <Option value="UNPUBLISHED">UNPUBLISHED</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Add Post
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    </>)
}

export default AddBlogPost