import { Card } from "antd";


const CardMetric = ({ title, extra, children, ...props }) => {
  return (
    <Card className='card card-metric' title={title} extra={extra} {...props}>
      {children}
    </Card>
  )
}

export default CardMetric;