import { List } from "antd";
import { Link } from "react-router-dom";


const BlogList = ({ blogs }) => {
    return (
        <List
            itemLayout="horizontal" // Ensures image is on the left
            dataSource={blogs}
            renderItem={(blog) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={
                            <img width={130} alt={blog.title} src={`/blog/${blog.path}.jpg`} />
                        }
                        title={<Link to={`/blog/${blog.path}`}>{blog.title}</Link>}
                        description={blog.description}
                    />
                </List.Item>
            )}
        />
    );
};

export default BlogList;