import React from 'react';
import { LiveProvider, LivePreview, LiveError } from 'react-live';

/**
 * DynamicComponent renders React JavaScript code retrieved from a database.
 * 
 * @param {string} code - The React code as a string, retrieved from the database.
 * @param {object} [scope] - Optional scope to provide additional components or variables.
 * @returns {JSX.Element} The rendered component or error message.
 */
const DynamicComponent = ({ code, scope = {} }) => {
  return (
    <LiveProvider code={code} scope={scope}>
      <LivePreview />
      <LiveError />
    </LiveProvider>
  )
}

export default DynamicComponent;