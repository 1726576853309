import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getPost = async (urlPath) => {
    const config = {
        url: `${apiServerUrl}/blog/post/${urlPath}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}

export const getPosts = async () => {
    const config = {
        url: `${apiServerUrl}/blog/posts`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}

export const addBlogPost = async (accessToken, blogPost) => {
	const config = {
		url: `${apiServerUrl}/api/blog/post`,
		method: "POST",
		data: blogPost,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}