import { animated, useChain, useInView, useSpring, useSpringRef } from '@react-spring/web';
import { isMobile } from '../../../utils/utils';
import { SignupButton } from '../../../components/buttons/signup-button';
import styles from '../../../styles/landings/pages/cta.module.css';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const CTA = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refP4, inViewP4] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.3 : 0.54,
    })

    const springRefLove = useSpringRef()
    const springLove = useSpring({
        ref: springRefLove,
        config: { duration: 1000 },
        from: { opacity: inViewP4 ? 0 : 1, },
        to: {
            opacity: inViewP4 ? 1 : 0,
        },
    })
    const springRefGetStarted = useSpringRef()
    const springGetStarted = useSpring({
        ref: springRefGetStarted,
        config: { duration: 900 },
        from: {
            opacity: inViewP4 ? 0 : 1,
            transform: 'translateX(-30)',
        },
        to: {
            opacity: inViewP4 ? 1 : 0,
            transform: inViewP4 ? 'translateX(0)' : 'translateX(-30px)'
        },
    })
    useChain([springRefLove, springRefGetStarted])


    return (
        <div className="page" ref={refP4} style={{ backgroundColor: '#000' }}>
            <div className="landing-content">
                <animated.div className={styles['value-title']} style={{ ...springLove, }}>
                    Join Thousands of Investors Who Took Control of Their Savings
                </animated.div>
                <animated.div style={{ ...springGetStarted, }}>
                    <SignupButton className={styles['value-signup']} label='Join The Club Now >' />
                </animated.div>
            </div>
        </div>
    )
}

export default CTA;