import { Col, Row } from "antd";
import styles from '../../../styles/landings/pages/Exchanges.module.css';
import { COLOR_GREY_BACKGROUND } from "../../../JTIConst";


const Exchanges = () => {

    return (
        <div className="page" style={{ backgroundColor: COLOR_GREY_BACKGROUND, padding: '1em', margin: '3em 0' }}>
            <div className="landing-content" >
                <Row gutter={[16, 16]} justify="space-between" align='middle'>
                    <Col xs={12} md={6}>
                        <img src='/logo_nyse.png' alt='Logo NYSE' className={styles['logo-nyse']} />
                    </Col>
                    <Col xs={12} md={6}>
                        <img src='/logo_nasdaq.png' alt='Logo NASDAQ' className={styles['logo-nasdaq']} />
                    </Col>
                    <Col xs={12} md={6}>
                        <img src='/logo_tmx.png' alt='Logo TMX' className={styles['logo-tmx']} />
                    </Col>
                    <Col xs={12} md={6}>
                        <img src='/logo_cse.png' alt='Logo CSE' className={styles['logo-cse']} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Exchanges;