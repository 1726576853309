import { gray } from '@ant-design/colors';
import { MenuOutlined, PlusSquareOutlined, ProfileOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Col, Divider, Drawer, Layout, Modal, Row, Segmented, Skeleton, Space, Tooltip, Tour, Typography, notification, theme } from 'antd';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import Typewriter from 'typewriter-effect';
import CandlestickChart from '../components/CandlestickChart.jsx';
import ROICard from '../components/cards/ROICard.jsx';
import SignalsCard from '../components/cards/SignalsCard.jsx';
import SwingTimeCard from '../components/cards/SwingTimeCard.jsx';
import WinRateCard from '../components/cards/WinRateCard.jsx';
import CandlestickChartToolbox from '../components/charts/CandlestickChartToolbox.jsx';
import FinancialChart from '../components/FinancialChart.jsx';
import JTIAnchor from '../components/JTIAnchor.jsx';
import Loading from '../components/Loading';
import { NotifUpgradePlan } from '../components/Notifications.jsx';
import PageTitle1 from '../components/PageTitle1.jsx';
import SankeyChart from '../components/SankeyChart.jsx';
import { MaxItemsWatchlist, MaxSymbolsViewed, TradingBookDescription } from '../components/TempLang';
import TickerInfo from '../components/TickerInfo.jsx';
import TickerLeftMenu from '../components/TickerLeftMenu.jsx';
import TickerTags from '../components/TickerTags.jsx';
import {
    tourStepAddToWatchlist,
    tourStepCandlestickChart,
    tourStepSignalsAndModelEfficiency,
    tourStepTags
} from '../components/tours/steps.jsx';
import TradingBook from '../components/TradingBook.jsx';
import UserContext from '../components/UserContext';
import { ASSET_TYPE_STOCK, GRID_GUTTER, TOOLTIP_ENTER_DELAY, TT_COLOR } from '../JTIConst';
import MetaTags from '../MetaTags.js';
import { isAllowedToViewTkr } from "../services/authorization.service";
import { getTickerInfos, getTickerQuote } from "../services/ticker.service";
import { addTickerToWatchlist } from "../services/user.service";
import { formatPriceChg, getGainLossColor, getLogoUrl, isMobile, isTablet, pourcentage } from '../utils/utils';


// PARAMETERS //

const IS_MOBILE = isMobile()
const IS_TABLET = isTablet()
const REAL_TIME_INTERVAL = 6000     // Each 6sec
const REAL_TIME_COUNT_OFF = 20      // Real time interval stop when counter reach x (no new quotes x times)


// SHARED VARIABLES //

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const anchorMenu = [
    {
        key: 'info',
        href: '#info',
    },
    {
        key: 'technical',
        href: '#technical',
    },
    {
        key: 'fundamentals',
        href: '#fundamentals',
    },
    {
        key: 'holdings',
        href: '#holdings',
    },
]


const TickerAnalysis = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [addToWatchButtColor, setAddToWatchButtColor] = useState(null);
    const [tkrInfos, setTkrInfos] = useState(null);
    const [pricePrefix, setPricePrefix] = useState('');
    const [priceChg, setPriceChg] = useState(null);
    const [candleInterval, setCandleInterval] = useState('daily');
    const [tickerInfoIsLoaded, setTickerInfoIsLoaded] = useState(false);
    const [tickerLeftMenuIsLoaded, setTickerLeftMenuIsLoaded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [efficiency, setEfficiency] = useState(null);
    const candleRef = useRef(null);
    const candleModalRef = useRef(null);

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const { paramTicker } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { getAccessTokenSilently } = useAuth0();
    const { userContext, updateUserContext, tourOpenChartAnalysis, setTourOpenChartAnalysis } = useContext(UserContext);
    const [api, contextHolder] = notification.useNotification();
    const [currTicker, setCurrTicker] = useState(null);
    const [leftMenuItems, setLeftMenuItems] = useState([]);
    const [allowedToViewTkr, setAllowedToViewTkr] = useState(true);
    const [candlesReloadKey, setCandlesReloadKey] = useState(0);

    // Price change animation
    const [springs, springApi] = useSpring(() => ({
        config: { duration: 800 },
        from: { opacity: 0.4 },
        to: {
            opacity: 1,
        },
    }))
    const animateChanges = () => {
        springApi.start({
            from: { opacity: 0.4 },
            to: {
                opacity: 1,
            },
        })
    }

    // Modal //

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // DRAWER //

    const [openDrawer, setOpenDrawer] = useState(searchParams.get('portfolioopen') ? true : false);

    const toggleDrawer = () => {
        if (openDrawer)
            setOpenDrawer(false)
        else
            setOpenDrawer(true)
    }

    const closeDrawer = () => {
        setOpenDrawer(false)

        // Reload candles display new indicators
        setCandlesReloadKey(prev => prev + 1)
    }


    // TOUR //

    const tourRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const endTour = () => {
        setTourOpenChartAnalysis(false)
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    const steps = [
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[0].current,
            placement: 'left'
        },
        {
            ...tourStepTags,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[2].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[3].current,
            placement: 'left',
            style: { width: '342px' }
        },
        // {
        //     ...tourStepFinancialCharts,
        //     target: () => tourRefs[4].current,
        //     placement: 'left',
        //     style: { width: '342px' }
        // },
        // {
        //     ...tourStepHoldingsChart,
        //     target: () => tourRefs[5].current,
        //     placement: 'left',
        //     style: { width: '342px' }
        // }
    ]

    const stepsMobile = [
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[0].current,
            placement: 'left'
        },
        {
            ...tourStepTags,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[2].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[3].current,
            style: { top: '92vh' }
        },
        // {
        //     ...tourStepFinancialCharts,
        //     target: () => tourRefs[4].current,
        // },
        // {
        //     ...tourStepHoldingsChart,
        //     target: () => tourRefs[5].current,
        // },
    ]

    // CANDLESTICK CHART //

    const segIntervals = [
        {
            value: 'daily',
            label: 'Daily',
        },
        // {
        //     value: 'weekly',
        //     label: 'Weekly',
        //     disabled: true
        // },
    ]

    const CandleList = {
        daily: <CandlestickChart ref={candleRef} id='candle-chart' interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} />,
        // hourly: <CandlestickChart id='candle-chart' interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} />,
    }

    const candleListModal = {
        daily: <CandlestickChart ref={candleModalRef} id='candle-chart-modal' interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='65vh' />,
        // hourly: <CandlestickChart id='candle-chart-modal' interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='85vh' />,
    }

    // PAGE CONTEXT //

    const addToWatchlist = async () => {

        // If item is not already present in the user watchlist
        if (userContext != null && !userContext.watchlist.some(tkrUserParam => tkrUserParam.ticker === currTicker)) {

            const accessToken = await getAccessTokenSilently();
            const resp = await addTickerToWatchlist(accessToken, userContext.id, currTicker)

            // MARK [AUTH] - Max items in watchlist
            if (resp.data == null) {
                api.info(NotifUpgradePlan(MaxItemsWatchlist))
            } else {
                setLeftMenuItems([...leftMenuItems, {
                    label: currTicker.toUpperCase(),
                    key: currTicker,
                    icon: <MenuOutlined />,
                }])

                userContext.watchlist.push({ ticker: currTicker, alert: true })   // Push it cause refresh too fast VS db is getting updated
                setAddToWatchButtColor(colorPrimary)
            }
        }
    }

    // Manage new symbol selected
    const processSetCurrTicker = async (tkr) => {

        window.history.replaceState(null, '', '/tickeranalysis/' + tkr);  // modifies the current history entry
        setCurrTicker(tkr)

        if (tkr != null && tkr != '') {

            // MARK [AUTH] - X symbols viewed per month
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await isAllowedToViewTkr(accessToken, tkr)
            setAllowedToViewTkr(data.isAllowedToViewTkr)

            // Show notif if not allowed
            if (data.isAllowedToViewTkr === false)
                api.info(NotifUpgradePlan(MaxSymbolsViewed))
        }
    }

    // When tab change
    const onTabChange = async (key, event) => {
        setCandleInterval(key)
    }

    // MARK : Manage real time price update
    let countOff = 1
    let intervalId = null
    const updatePriceChg = async (tkr) => {
        const accessToken = await getAccessTokenSilently()
        const quotesResp = await getTickerQuote(accessToken, tkr)
        const lastQuote = quotesResp.data

        setPricePrefix(
            lastQuote.preMarketPrice ? 'pre-mkt: ' : lastQuote.afterMarketPrice ? 'after-mkt: ' : ''
        )

        const calculateChange = (newPrice, oldPrice) => {
            const chg = pourcentage(newPrice, oldPrice);
            setPriceChg(prev => {
                if (prev !== chg) {
                    countOff = 0;
                    animateChanges();
                    return chg;
                }
                return prev;
            })
        }

        if (lastQuote?.afterPreMarketPrice && lastQuote?.close) {
            calculateChange(lastQuote.afterPreMarketPrice, lastQuote.close);
        } else if (lastQuote?.close && lastQuote?.prevClose) {
            calculateChange(lastQuote.close, lastQuote.prevClose);
        }

        // Stop real time if counter is reach > clear interval
        if (countOff >= REAL_TIME_COUNT_OFF)
            clearInterval(intervalId)
        else
            countOff++
    }


    // EFFECTS //

    useEffect(() => {

        if (userContext != null) {

            // Coming from URL with param
            if (paramTicker)
                processSetCurrTicker(paramTicker)  // TODO : Security vulnerability > filter url parameter part of ticker list

            // 1st item selected
            else if (userContext.watchlist.length > 0)
                processSetCurrTicker(userContext.watchlist[0].ticker)
        }

    }, [userContext])

    useEffect(() => {
        setIsLoading(true)

        if (currTicker != null && currTicker != '') {
            const fetchData = async () => {
                const accessToken = await getAccessTokenSilently();

                const { data, error } = await getTickerInfos(accessToken, currTicker)
                setTkrInfos(data)

                const quotesResp = await getTickerQuote(accessToken, currTicker)
                const lastQuote = quotesResp.data

                setPriceChg(
                    lastQuote.preMarketPrice
                        ? pourcentage(lastQuote.afterPreMarketPrice, lastQuote.close)
                        : lastQuote.afterMarketPrice
                            ? pourcentage(lastQuote.afterPreMarketPrice, lastQuote.close)
                            : pourcentage(lastQuote.close, lastQuote.prevClose)
                )
                setPricePrefix(
                    lastQuote.preMarketPrice ? 'pre-mkt: ' : lastQuote.afterMarketPrice ? 'after-mkt: ' : ''
                )

                // Define interval
                setEfficiency(data.oneDay)
                // if (candleInterval == 'hourly') {
                //     efficiency = tkrInfos.oneHour
                // }
            }
            fetchData()

            if (userContext.watchlist.some(tkrUserParam => tkrUserParam.ticker === currTicker)) {
                setAddToWatchButtColor(gray[1])
            } else {
                setAddToWatchButtColor('black')
            }

            setIsLoading(false)

            if (intervalId == null) {
                intervalId = setInterval(updatePriceChg, REAL_TIME_INTERVAL, currTicker)

                // Clear interval on component unmount
                return () => clearInterval(intervalId)
            }
        }
    }, [currTicker])

    // Manage first tour on desktop
    useEffect(() => {
        if (tickerInfoIsLoaded && tickerLeftMenuIsLoaded && userContext.tourChartAnalysis) {
            setTourOpenChartAnalysis(true)

            // Tour done > set flag to false
            userContext.tourChartAnalysis = false
            updateUserContext(userContext)
        }
    }, [tickerLeftMenuIsLoaded, tickerInfoIsLoaded])

    // Manage first tour on mobile
    useEffect(() => {
        if (isMobile() && tickerInfoIsLoaded && userContext.tourChartAnalysis) {
            setTourOpenChartAnalysis(true)

            // Tour done > set flag to false
            userContext.tourChartAnalysis = false
            updateUserContext(userContext)
        }
    }, [tickerInfoIsLoaded])


    // RETURN //

    // Loading not ready
    if (userContext == null || currTicker === null || tkrInfos == null || efficiency == null) {
        return <Loading />
    }

    return (<>
        {contextHolder}

        {!IS_TABLET &&
            <JTIAnchor items={tkrInfos?.assetType === ASSET_TYPE_STOCK ? anchorMenu : anchorMenu.slice(0, 2)} />
        }

        <Layout className="site-layout-content">
            <MetaTags title="Just Trade It: AI Charts" />

            <Drawer width={1000} zIndex={200} placement="right" closable={true} onClose={closeDrawer} open={openDrawer} title={(<Title level={3} style={{ margin: '0' }}><ProfileOutlined /> Portfolio</Title>)}>
                <Paragraph>
                    <TradingBookDescription />
                </Paragraph>
                <TradingBook viewMode={false} />
            </Drawer>

            {/* Left menu */}
            {!IS_MOBILE &&
                <TickerLeftMenu
                    setTickerLeftMenuIsLoaded={setTickerLeftMenuIsLoaded}
                    tourRef={tourRefs[0]}
                    items={leftMenuItems}
                    setItems={setLeftMenuItems}
                    currItem={currTicker}
                    setCurrItem={processSetCurrTicker}
                    setCandlesReloadKey={setCandlesReloadKey} />
            }

            <Content>
                {
                    (userContext.watchlist.length == 0 && (currTicker === null || tkrInfos == null)) ?

                        // Case watchlist is empty
                        <Paragraph style={{ marginTop: '1em', }}>
                            <Typewriter options={{
                                strings: 'Start by selecting a symbol from the search bar.',
                                delay: 20,
                                autoStart: true,
                                loop: false,
                            }} />
                        </Paragraph>


                        :

                        // Happy path
                        <>
                            <PageTitle1 id='info'>
                                <Space align='baseline' size={0} style={{ float: 'right', }}>
                                    <Tooltip placement='top' color={TT_COLOR} title="Open portfolio" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button ref={tourRefs[8]} type="text" onClick={toggleDrawer} icon={<ProfileOutlined className='button-icon' />} />
                                    </Tooltip>
                                    <Tooltip placement='top' color={TT_COLOR} title="Add to watchlist" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button ref={tourRefs[0]} type="text" icon={<PlusSquareOutlined style={{ fontSize: '1.3em', color: addToWatchButtColor }} />} onClick={addToWatchlist} />
                                    </Tooltip>
                                </Space>
                                <img className='page-title1-logo' width={35} src={getLogoUrl(currTicker)} /> {tkrInfos.shortName?.replace(/\s+\(The\)$/i, '')} • <animated.span style={{ ...springs, fontSize: '0.7em', color: getGainLossColor(priceChg) }}><span className='price-prefix'>{pricePrefix}</span>{formatPriceChg(priceChg)}</animated.span>
                            </PageTitle1>

                            {
                                allowedToViewTkr === false ?

                                    // Not allowed to view ticker
                                    <Skeleton paragraph={{ rows: 4 }} />
                                    :
                                    <>
                                        <TickerTags tkrInfos={tkrInfos} tourRef={tourRefs[1]} />

                                        <TickerInfo tkrInfos={tkrInfos} interval={candleInterval} isLoading={isLoading} setTickerInfoIsLoaded={setTickerInfoIsLoaded} tourRefs={tourRefs} ticker={currTicker} addToWatchList={addToWatchlist} />

                                        {/* <Title level={5} style={{ color: 'grey', marginTop: '1em' }}>Efficiency</Title> */}
                                        <Divider id='technical' orientation="left" style={{ color: 'grey' }}>Technical Analysis</Divider>

                                        {/* <Row gutter={GRID_GUTTER_S} className='grid-bottom-s'>
                                            <Col xs={24}>
                                                <Card className='card-filled'>
                                                    {ExplainAIEfficiency(efficiency.total_win_percent.toFixed(2), efficiency.roi_avg_all.toFixed(2))}
                                                </Card>
                                            </Col>
                                        </Row> */}

                                        {/* METRICS CARDS */}

                                        <Row ref={tourRefs[2]} gutter={GRID_GUTTER} className='grid-bottom'>
                                            <Col xs={12} md={6}>
                                                <SignalsCard efficiency={efficiency} />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <WinRateCard percent={efficiency.total_win_percent / 100} />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <ROICard percent={efficiency.roi_avg_all.toFixed(2)} />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <SwingTimeCard minValue={efficiency.swingTimeMin} maxValue={efficiency?.swingTimeMax} currentValue={efficiency?.swingTimeMedian} />
                                            </Col>
                                        </Row>

                                        <Card ref={tourRefs[3]} className='card-chart-candle grid-bottom'
                                            title={<Segmented options={segIntervals} onChange={onTabChange} />}
                                            extra={<CandlestickChartToolbox chartRef={candleRef} tkrInfos={tkrInfos} fullScreen={showModal} />}>
                                            {CandleList[candleInterval]}
                                        </Card>

                                        {
                                            tkrInfos.assetType === ASSET_TYPE_STOCK && (<>
                                                <Divider orientation="left" style={{ color: 'grey' }}>Fundamentals Analysis</Divider>
                                                <div ref={tourRefs[4]} className='grid-bottom'>
                                                    <FinancialChart tkrInfos={tkrInfos} />
                                                </div>
                                                <div ref={tourRefs[5]}>
                                                    <SankeyChart tkrInfos={tkrInfos} />
                                                </div>
                                            </>)
                                        }
                                    </>
                            }
                        </>
                }
            </Content>
        </Layout>

        <Modal wrapClassName='modal-chart-candle' title={tkrInfos?.longName} open={isModalVisible} onCancel={handleCancel} footer={null} width='95%' centered>
            <CandlestickChartToolbox chartRef={candleModalRef} style={{ margin: '1.5em 0 2em 0' }} tkrInfos={tkrInfos} fullScreen={showModal} />
            {candleListModal[candleInterval]}
        </Modal>

        {!isMobile() ? (
            // TODO : Fix behavior: 'smooth'
            <Tour open={tourOpenChartAnalysis} onClose={endTour} steps={steps} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} />
        ) : (
            <Tour open={tourOpenChartAnalysis} onClose={endTour} steps={stepsMobile} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} arrow={false} />
        )}
    </>)
}

export default TickerAnalysis;