import { Layout, Typography, List, Divider, Row, Col, Image } from 'antd';
import { Disclaimer } from '../components/TempLang';
import PageTitle1 from '../components/PageTitle1';
import MetaTags from '../MetaTags';
import { JTI_EMAIL_CONTACT } from '../JTIConst';

const { Title, Paragraph, Text } = Typography;
const { Item } = List;


const AboutJTI = () => {

    const titleSize = 3

    return (
        <Layout className="site-layout-content-fixed">
            <MetaTags
                title="Just Trade It: About JTI" />

            <PageTitle1> • About Just Trade It (JTI)</PageTitle1>

            <Title level={titleSize}>Why Just Trade It?</Title>
            <Paragraph>
                The financial market is one of the biggest drivers of our economy, and it's surprising how many people have limited knowledge about it.
                Many individuals are uncertain about what to do with their savings, which is why we've created tools to help address this dilemma.
                Just Trade It has been built on these fundamental beliefs that represent our goals:

                <List style={{ margin: '5px 0 15px 30px' }}>
                    <Item>
                        <blockquote>
                            <Text strong className='text-red'>"Making people invest in what they love": </Text>
                            Supporting investments in products or services individuals believe in can elevate the economy to new heights.
                            Just as we strive to consume responsibly in our daily expenses, we should also invest responsibly—it’s equally, if not more, important. We shouldn’t leave these decisions entirely to others.
                            We believe the financial market shouldn’t be controlled solely by financial institutions. Retail investors hold significant power, as exemplified by the GameStop story.
                        </blockquote>
                    </Item>
                    <Item>
                        <blockquote>
                            <Text strong className='text-red'>"Government pension plans are falling short": </Text>
                            With rising costs, longer life expectancies, and aging populations, these plans often can’t meet retirement needs. 
                            To safeguard your future and maintain your lifestyle, it’s essential to take control by investing and creating additional income streams today.
                        </blockquote>
                    </Item>
                    <Item>
                        <blockquote>
                            <Text strong className='text-red'>“You’re falling behind if you don’t learn how to make your money work for you”: </Text>
                            Learning to grow your savings is essential for achieving financial freedom. As the economy accelerates and inflation rises, failing to adapt means losing value year after year.
                            Taking action, even small steps, is always better than doing nothing.
                        </blockquote>
                    </Item>
                </List>
            </Paragraph>
            <Paragraph>
                We believe that by achieving these goals, we can make a positive impact on the financial well-being of individuals and the broader economy.
                Thank you for being a part of our journey.
            </Paragraph>

            <Title level={titleSize}>Founder</Title>
            <Paragraph>
                <Row gutter={40}>
                    <Col span={9}>
                        <Image src='/founder.jpg' />
                    </Col>
                    <Col span={15}>
                        <Paragraph>Hello,</Paragraph>
                        <br />
                        <Paragraph>
                            I'm Michaël Moo Penn, the founder of <b>Just Trade It</b>. With over 15 years of experience in the IT industry, I've witnessed the transformative power of technology.
                        </Paragraph>
                        <Paragraph>
                            Nine years ago, I ventured into the world of trading, discovering a new passion. By combining my technical expertise with trading experience,
                            I began using <b>AI Models</b> that significantly improved my trading outcomes.<br />
                            A year ago, I decided to share my knowledge with the world, embarking on the journey to create <b>Just Trade It</b>.
                        </Paragraph>
                        <Paragraph>
                            <b>JTI</b> is the result of my love for trading and technology, providing a <b>data-driven insights platform</b> that empowers traders like you to navigate the complexities of the stock market.
                        </Paragraph>
                        <Paragraph>
                            As a trader with a deep passion for technology, I understand the challenges and opportunities in this dynamic landscape.
                            I recognize the importance of reliable data, real-time insights, and a platform that aligns with your trading goals.
                        </Paragraph>
                        <Paragraph>
                            I invite you to explore <b>Just Trade It</b>, benefit from the wealth of insights it offers, and join our community of traders.
                            Together, we can unleash the full potential of <b>data-driven trading</b> and make smarter, more informed decisions in the financial markets.
                        </Paragraph>
                        <Paragraph>
                            Thank you for being part of our journey, and I eagerly anticipate your success and feedback.
                        </Paragraph>
                        <Paragraph>
                            <br />
                            Warm regards,<br /><br />

                            Michaël Moo Penn<br /><br />
                        </Paragraph>
                    </Col>
                </Row>
            </Paragraph>

            <Title level={titleSize}>Contact Us</Title>
            <Paragraph>
                You can reach us for any request to <a href={`mailto:${JTI_EMAIL_CONTACT}`}>{JTI_EMAIL_CONTACT}</a>
            </Paragraph>

            <br />
            <Divider />
            <Title level={5} style={{ marginTop: '0' }}><i>Disclaimer</i></Title>
            <Paragraph><Disclaimer /></Paragraph>
        </Layout>
    )
}

export default AboutJTI;