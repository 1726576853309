
import { Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Navigation from '../components/Navigation.jsx';
import JTIFooter from "../components/JTIFooter.jsx";
import { Typography, Layout } from 'antd';
import NewsNotifications from "../components/NewsNotifications.jsx";
import FloatMenu from '../components/FloatMenu.jsx';
import MetaTags from "../MetaTags.js";

const { Link } = Typography
const { Header, } = Layout

const AppLayout = () => {
    const { isLoading, isAuthenticated } = useAuth0()
    const location = useLocation()

    // MARK : Check Auth0 loading before component rendering (maybe this logic can be moved to UserProvider.js)
    // TODO : Not sure to be used
    if (isLoading) {
        return;
    }

    return (
        <Layout className="site-layout">
            <MetaTags />

            <NewsNotifications />

            {/* Header not fixed on landing */}
            <Header className="header" style={location.pathname === "/" ? { position: "absolute" } : { position: "fixed" }}>
                <Link href="/">
                    <img className="logo" alt="Just Trade It logo" src="/logo_b_l.svg" />
                </Link>
                <Navigation />
            </Header>

            <Outlet />

            {   // Display float button only if user is authenticated
                isAuthenticated && <FloatMenu />
            }

            <JTIFooter />

        </Layout>
    )
}

export default AppLayout;