import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { IconLoading } from './Icons';
import TickerInfoDescCrypto from './TickerInfoDescCrypto';
import TickerInfoDescETF from './TickerInfoDescETF';
import TickerInfoDescStock from './TickerInfoDescStock';



// PARAMETERS //

const styleItalic = { fontSize: 'smaller' }


const TickerInfo = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    // Let Tour run after the component is loaded, otherwise tour won't work
    useEffect(() => {
        props.setTickerInfoIsLoaded(true)
    })

    if (props.tkrInfos === null || props.isLoading) {
        return (
            <div style={{ height: '20em', alignItems: 'center', display: 'flex' }}>
                <IconLoading />
            </div>)
    }

    const tkrInfos = props.tkrInfos
    // console.log(JSON.stringify(tkrInfos))

    var descriptions = ''
    switch (tkrInfos.assetType) {
        case 'STOCK':
            descriptions = <TickerInfoDescStock tkrInfos={tkrInfos} />
            break;
        case 'ETF':
            descriptions = <TickerInfoDescETF tkrInfos={tkrInfos} />
            break;
        case 'CRYPTO':
            descriptions = <TickerInfoDescCrypto tkrInfos={tkrInfos} />
            break;
    }

    return (<>
        <Card className='card grid-bottom'>
            {descriptions}
        </Card>


        {/* <ModalBasic open={isModalVisible} setOpen={setIsModalVisible} icon={getLogoUrl(tkrInfos.ticker)}
            title={tkrInfos.shortName}>
            <Paragraph className="modal-paragraph reading">
                {ExplainAIEfficiency(winRate, avgROI)}
            </Paragraph>
        </ModalBasic> */}
    </>)
}

export default TickerInfo;