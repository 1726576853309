import { Progress } from "antd";
import { IconInfoAVGROI } from "../Icons";
import CardMetric from "./CardMetric";
import { COLOR_GREEN } from "../../JTIConst";
import CountUp from "react-countup";


const ROICard = ({ percent }) => {
    return (
        <CardMetric title={<>Average Return</>} extra={<IconInfoAVGROI />}>
            <Progress
                type='line'
                percent={percent}
                showInfo={false}
                steps={28}
                size={{ width: 3, height: 40 }}
                strokeColor={COLOR_GREEN}
                style={{ margin: '0.5em auto' }} />
            <div style={{ fontSize: '1.6em', color: COLOR_GREEN }}>
                <CountUp end={percent} decimals={2} separator="." />%
            </div>
        </CardMetric>
    )
}

export default ROICard;