import { PlusOutlined } from '@ant-design/icons';
import { animated, useChain, useInView, useSpring, useSpringRef } from '@react-spring/web';
import { List, Row } from 'antd';
import { Link } from 'react-router-dom';
import { COLOR_PRIMARY } from '../../../JTIConst';
import styles from '../../../styles/faq.module.css';
import { isMobile } from '../../../utils/utils';
import { IN_VIEW, IN_VIEW_MOBILE } from '../../Home';
import { faqQuestions } from '../../FAQ';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const FAQ = () => {

    // OBSERVER & SPRING EFFECTS //

    // const [refObserver, inView] = useInView({
    //     triggerOnce: true,
    //     threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    // })

    // const springRefTitle = useSpringRef()
    // const springTitle = useSpring({
    //     ref: springRefTitle,
    //     config: { duration: 600 },
    //     from: { opacity: inView ? 0 : 1, },
    //     to: {
    //         opacity: inView ? 1 : 0
    //     },
    // })

    // const springRefList = useSpringRef()
    // const springList = useSpring({
    //     ref: springRefList,
    //     config: { duration: 400 },
    //     from: { opacity: inView ? 0 : 1, },
    //     to: {
    //         opacity: inView ? 1 : 0,
    //     },
    // })

    // useChain([springRefTitle, springRefList], [0, 1], 600)


    return (
        <div className="page">
            <div className="landing-content">
                <Row justify="space-around">
                    <animated.div className='page-title'>
                        <span style={{ color: COLOR_PRIMARY }}>Frequently Asked Questions</span>
                    </animated.div>
                </Row>
                <Row gutter={{ xs: 0, sm: 90 }} justify="space-around">
                    <animated.div>
                        <List size='large' bordered className={styles['list-questions']}
                            itemLayout="horizontal"
                            dataSource={faqQuestions.slice(-5)}
                            renderItem={(item) => (
                                <List.Item
                                    extra={ !IS_MOBILE &&
                                        <Link className="none-color-link" to={item.link}><PlusOutlined /></Link>}>
                                    <Link className="none-color-link" to={item.link}>{item.title}</Link>
                                </List.Item>
                            )}
                        />
                    </animated.div>
                </Row>
            </div>
        </div>
    )
}

export default FAQ;