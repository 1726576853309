import { Card, Col, Layout, Row, theme, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageTitle1 from '../components/PageTitle1';
import { GRID_GUTTER } from '../JTIConst';
import MetaTags from '../MetaTags';
import { getPosts } from '../services/blog.service';
import { formatDateForBlog } from '../utils/utils';


const { Paragraph } = Typography;


export const BLOG_CRUMB = [
    {
        title: <Link to="/">Home</Link>,
    },
    {
        title: <Link to="/blog">Blog</Link>,
    },
]

export const blogPosts = [
    {
        title: "The Swing Resilient Strategy",
        description: "Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions...",
        createdDate: "3/4/2024",
        updatedDate: "3/4/2024",
        path: "swing-resilient-strategy",
    },
    {
        title: "Just Trade It: Trading Checklist",
        description: "Before buying any asset, please check a few fundamental points to maximize your chances of...",
        createdDate: "9/16/2024",
        updatedDate: "9/16/2024",
        path: "trading-checklist",
    },
    {
        title: "Common Stocks vs. Preferred Stocks: Which One Should You Choose?",
        description: "When it comes to stock market investing, understanding the different types of stocks...",
        createdDate: "12/20/2024",
        updatedDate: "12/20/2024",
        path: "common-stocks-vs-preferred-stocks",
    },
    {
        title: "The Basics of Dividend Investing: How to Create a Passive Income Stream",
        description: "Dividend investing has long been regarded as one of the most effective ways to build wealth...",
        createdDate: "12/20/2024",
        updatedDate: "12/20/2024",
        path: "the-basics-of-dividend-investing",
    },
    {
        title: "Top 10 Growth Stocks to Watch in 2025",
        description: "Growth stocks are typically companies that are expected to outperform the broader market...",
        createdDate: "1/2/2025",
        updatedDate: "1/2/2025",
        path: "top-10-growth-stocks-2025",
    },
    {
        title: "Stock Market Investing: A Guide to Building Wealth with Confidence",
        description: "Investing in the stock market is a proven way to grow your wealth and achieve financial goals...",
        createdDate: "1/2/2025",
        updatedDate: "1/2/2025",
        path: "stock-market-investing",
    },
    {
        title: "Understanding Ratios, Terms, and Metrics in Fundamental Analysis for Stock Market Investing",
        description: "Fundamental analysis is the method of evaluating a company’s intrinsic value by examining its...",
        createdDate: "1/6/2025",
        updatedDate: "1/6/2025",
        path: "understanding-ratios-terms-and-metrics-in-fundamental-analysis-for-stock-market-investing",
    },
    {
        title: "Understanding Price-to-Earnings (P/E) Ratio: How to Evaluate Stock Valuation",
        description: "The Price-to-Earnings (P/E) ratio is one of the most widely used and important metrics in stock market...",
        createdDate: "1/16/2025",
        updatedDate: "1/16/2025",
        path: "price-to-earnings-ratio",
    },
    {
        title: "Understanding Index Funds: A Beginner’s Guide to Passive Investing",
        description: "Index funds are a popular investment option for beginners and experienced investors alike, offering an easy way...",
        createdDate: "1/21/2025",
        updatedDate: "1/21/2025",
        path: "index-funds",
    },
    {
        title: "How to Build a Diversified Stock Portfolio: Tips and Strategies",
        description: "Building a diversified stock portfolio is one of the most effective ways to reduce risk and increase...",
        createdDate: "1/21/2025",
        updatedDate: "1/21/2025",
        path: "diversified-stock-portfolio",
    },
    {
        title: "Top 5 Books Every Stock Market Investor Should Read",
        description: "Stock market investing can seem overwhelming at first, but with the right knowledge, tools, and mindset...",
        createdDate: "2/6/2025",
        updatedDate: "2/6/2025",
        path: "top-investing-books",
    },
    {
        title: "The Role of Technical Analysis in Stock Market Investing",
        description: "Technical analysis is a method used by investors and traders to evaluate and predict future price...",
        createdDate: "2/8/2025",
        updatedDate: "2/8/2025",
        path: "technical-analysis-stock-market",
    },
    {
        title: "Price-to-Book (P/B) Ratio Explained: How to Identify Undervalued Stocks",
        description: "The Price-to-Book (P/B) ratio is another important financial metric used in fundamental analysis to evaluate....",
        createdDate: "2/12/2025",
        updatedDate: "2/12/2025",
        path: "price-to-book-ratio",
    },
    {
        title: "How to Analyze a Company’s Financial Statements for Stock Investing",
        description: "The Price-to-Book (P/B) ratio is another important financial metric used in fundamental analysis to evaluate....",
        createdDate: "2/19/2025",
        updatedDate: "2/19/2025",
        path: "financial-statements-analysis",
    },
]

const Blog = () => {

    const [posts, setPosts] = useState([]);
    const {
        token: { colorTextDescription },
    } = theme.useToken();


    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await getPosts()

            const posts = blogPosts.concat(data)
            const sortedPosts = posts.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate));

            setPosts(sortedPosts)
        }
        fetchData()
    }, [])


    return (
        <Layout className="site-layout-content">
            <MetaTags
                title="Just Trade It: Blog"
                description="Explore insightful articles and tutorials on trading to enhance your knowledge and skills. Dive into AI-powered trading strategies, 
                market trends, and expert insights to make smarter investment decisions with Just Trade It." />

            <PageTitle1>• Blog</PageTitle1>

            <Row gutter={GRID_GUTTER}>
                {posts.map((post, index) => (
                    <Col xs={{ span: 12 }} md={{ span: 6 }} key={index}>
                        <Link to={`/blog/${post.path}`}>
                            <Card className='card' bordered hoverable
                                cover={<img alt={post.title} src={`/blog/${post.path}.jpg`} />}>
                                <Meta title={post.title}
                                    description={<>
                                        <Paragraph ellipsis={{ rows: 3 }} style={{ color: colorTextDescription }}>
                                            {post.description}
                                        </Paragraph>
                                        <div style={{ float: 'right', fontStyle: 'italic' }}>
                                            {formatDateForBlog(post.updatedDate)}
                                        </div>
                                    </>} />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Layout>
    );
};

export default Blog;