import { Image, Layout, Typography } from 'antd';
import React from 'react';
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';
import BlogTitle1 from '../../components/BlogTitle1';
import PageTitle2 from '../../components/PageTitle2';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { BLOG_CRUMB, blogPosts } from '../Blog';
import MyBreadcrumb from '../../components/Breadcrumb';
import { SignupButton } from '../../components/buttons/signup-button';
import PageTitle3 from '../../components/PageTitle3';
import BlogList from '../../components/blog/BlogList';

const { Paragraph } = Typography;


const relatedPosts = [4, 9, 10]


const FinancialStatementAnalysis = ({ title, path, updatedDate }) => {

    return (
        <Layout className="site-layout-content-fixed site-layout-content-blog">
            <MetaTags
                title={title}
                description="Learn how to analyze financial statements for stock investing. Understand income statements, balance sheets, and cash flow statements to assess a company's financial health."
                keywords="financial statement analysis, income statement, balance sheet, cash flow statement, stock investing, financial ratios, investment strategy" />

            <MyBreadcrumb items={BLOG_CRUMB.concat([
                {
                    title: title,
                }
            ])} />

            <BlogTitle1>
                • {title}
                <div className='blog-title-meta'>{updatedDate}</div>
            </BlogTitle1>

            <Paragraph className='blog-paragraph'>
                <Image width="100%" src={`/blog/${path}.jpg`} alt="Analyzing financial statements for stock investment decisions" />
            </Paragraph>

            <Paragraph className='blog-paragraph'>
                Financial statement analysis is a crucial skill for stock market investors, as it helps you assess the financial health of a company before making investment decisions. By examining a company’s income statement, balance sheet, and cash flow statement, you can gain valuable insights into its profitability, solvency, and overall financial stability. This blog will walk you through the key financial statements and how to analyze them to make better stock investing decisions.
            </Paragraph>

            <PageTitle2>What Are Financial Statements?</PageTitle2>
            <Paragraph className='blog-paragraph'>
                Financial statements are official records of a company’s financial activities and provide a snapshot of its financial performance over a specific period. The three primary financial statements are:
            </Paragraph>

            <ul className='blog-list'>
                <li><b>Income Statement (Profit & Loss Statement):</b> Shows a company’s revenue, expenses, and profit over a period.</li>
                <li><b>Balance Sheet:</b> Displays a company’s assets, liabilities, and equity at a specific point in time.</li>
                <li><b>Cash Flow Statement:</b> Provides an overview of cash inflows and outflows during a specific period.</li>
            </ul>

            <Paragraph className='blog-paragraph'>
                Each of these statements gives investors different perspectives on the company’s financial health and performance.
            </Paragraph>

            <PageTitle2>Key Financial Statements and What They Tell You</PageTitle2>

            <PageTitle3>Income Statement</PageTitle3>
            <Paragraph className='blog-paragraph'>
                <b>What It Is:</b> The income statement summarizes a company’s revenues, costs, and expenses over a period (usually quarterly or annually).
            </Paragraph>

            <ul className='blog-list'>
                <li><b>Revenue:</b> The total income generated by the company from its business activities. Look for steady growth in revenue.</li>
                <li><b>Gross Profit:</b> Revenue minus the cost of goods sold (COGS). A healthy gross profit margin is a positive indicator.</li>
                <li><b>Operating Income (EBIT):</b> Earnings before interest and taxes. This shows how well the company is performing from its core business activities.</li>
                <li><b>Net Income:</b> The company’s profit after all expenses, including taxes and interest. Positive net income indicates profitability.</li>
            </ul>

            <Paragraph className='blog-paragraph'>
                <i>Example: In Tesla (TSLA), a growing revenue and increasing net income year-over-year indicate the company’s strong business performance, while improving gross margins signal efficient cost management.</i>
            </Paragraph>

            <PageTitle3>Balance Sheet</PageTitle3>
            <Paragraph className='blog-paragraph'>
                <b>What It Is:</b> The balance sheet lists a company’s assets, liabilities, and shareholder equity at a specific point in time, offering a snapshot of its financial position.
            </Paragraph>

            <ul className='blog-list'>
                <li><b>Assets:</b> What the company owns, including cash, inventory, and equipment. Higher total assets can indicate growth.</li>
                <li><b>Liabilities:</b> What the company owes, such as debt and accounts payable. A manageable level of liabilities is essential for long-term stability.</li>
                <li><b>Shareholder Equity:</b> The difference between assets and liabilities. Positive equity means the company has more assets than liabilities, a sign of financial strength.</li>
            </ul>

            <Paragraph className='blog-paragraph'>
                <i>Example: Apple (AAPL) has a large cash reserve, which provides the company with financial flexibility and resilience, even during market downturns.</i>
            </Paragraph>

            <PageTitle3>Cash Flow Statement</PageTitle3>
            <Paragraph className='blog-paragraph'>
                <b>What It Is:</b> The cash flow statement shows how cash moves in and out of the company during a specific period. It is divided into three categories: operating, investing, and financing cash flows.
            </Paragraph>

            <ul className='blog-list'>
                <li><b>Operating Cash Flow:</b> Cash generated from the company’s core business operations. Positive operating cash flow is a sign of financial health.</li>
                <li><b>Investing Cash Flow:</b> Cash used for investments in assets or acquisitions. Negative investing cash flow can be normal for growth companies that invest heavily in expansion.</li>
                <li><b>Financing Cash Flow:</b> Cash received from or paid to investors and creditors. Large amounts of financing cash flow can indicate that the company is raising funds, which could be a red flag if the business is not generating enough from operations.</li>
            </ul>

            <Paragraph className='blog-paragraph'>
                <i>Example: Microsoft (MSFT) generates strong operating cash flow, which allows the company to reinvest in its business, pay dividends, and repurchase shares.</i>
            </Paragraph>

            <PageTitle2>Ratios to Help Analyze Financial Statements</PageTitle2>
            <ol className='blog-list'>
                <li><b>Price-to-Earnings (P/E) Ratio:</b> Compares a company’s current share price to its earnings per share (EPS). A high P/E ratio may suggest overvaluation, while a low P/E may indicate undervaluation.</li>
                <li><b>Current Ratio:</b> Calculated by dividing current assets by current liabilities. A ratio above 1 indicates that a company can cover short-term obligations.</li>
                <li><b>Return on Equity (ROE):</b> Measures how efficiently a company uses equity to generate profits.</li>
                <li><b>Debt-to-Equity Ratio:</b> Compares a company’s total debt to its shareholder equity.</li>
            </ol>

            <PageTitle2>Real-World Example: Analyzing Amazon’s Financial Statements</PageTitle2>
            <ul className='blog-list'>
                <li><b>Income Statement:</b> Amazon consistently reports strong revenue growth, with AWS contributing high margins.</li>
                <li><b>Balance Sheet:</b> Amazon has significant assets, including warehouses and technology infrastructure, with strong cash flow.</li>
                <li><b>Cash Flow Statement:</b> Strong operating cash flow supports reinvestment and debt management.</li>
            </ul>

            <PageTitle2>Conclusion</PageTitle2>
            <Paragraph className='blog-paragraph'>
                Analyzing a company’s financial statements is essential for understanding its financial health and making informed investment decisions. By examining key financial statements and using financial ratios, investors can gain deeper insights into a company’s performance.
            </Paragraph>

            <SignupButton className="blog-cta" type='primary' label='Start Analyzing Financial Statements Today' />

            <PageTitle3>Related Blog Posts</PageTitle3>
            <BlogList blogs={blogPosts.filter((_, index) => relatedPosts.includes(index))} />

            <BlogPostNavigationBottom />
        </Layout>
    )
}

export default FinancialStatementAnalysis;