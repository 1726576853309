import { EditOutlined, InfoOutlined, PlaySquareOutlined, QuestionOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from '../components/UserContext';
import ModalFeedback from './modals/ModalFeedback';


const FloatMenu = () => {

    const { setTourOpenChartAnalysis } = useContext(UserContext);
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    let openModal = false
    if (searchParams.get('feedback') === 'true') {
        openModal = true
    }
    const [openModalFeeback, setOpenModalFeebac] = useState(openModal);


    return (<>
        <ModalFeedback open={openModalFeeback} setOpen={setOpenModalFeebac} />

        <FloatButton.Group trigger="click" type="primary" className="float-button" icon={<QuestionOutlined />}>
            <FloatButton icon={<EditOutlined />} onClick={() => setOpenModalFeebac(true)} tooltip='Write Review / Request' />
            <FloatButton icon={<InfoOutlined />} onClick={() => navigate('/jti-ai-model')} tooltip='AI Model Informations' />
            {location.pathname?.startsWith('/tickeranalysis') &&
                <FloatButton icon={<PlaySquareOutlined />} onClick={() => setTourOpenChartAnalysis(true)} tooltip='Replay Starting Tour' />
            }
        </FloatButton.Group>
    </>
    )
}

export default FloatMenu;