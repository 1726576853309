import { Col, Row, Space } from "antd";
import { IconInfoSignals, IconSignalBuyL1, IconSignalBuyL2, IconSignalOff, IconSignalSellL1, IconSignalSellL2 } from "../Icons";
import CardMetric from "./CardMetric";
import { COLOR_GREY_LIGHT } from "../../JTIConst";
import { isMobile } from "../../utils/utils";


const IS_MOBILE = isMobile()
const align = IS_MOBILE ? 'left' : 'right'
const space = IS_MOBILE ? 'middle' : 'large'


const SignalsCard = ({ efficiency }) => {
  return (
    <CardMetric title="Signals" extra={<IconInfoSignals />}>
      <Row>
        <Col xs={5} md={7} className='card-metric-label' align={align}>
          Buy
        </Col>
        <Col xs={19} md={17}>
          <Space size={space}>
            <span>{(efficiency.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />)}&nbsp; L1</span>
            <span>{(efficiency.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />)}&nbsp; L2</span>
          </Space>
        </Col>
      </Row>
      <div style={{ width: '80%', borderBottom: '1px solid ' + COLOR_GREY_LIGHT, margin: '1em auto' }} />
      <Row>
        <Col xs={5} md={7} className='card-metric-label' align={align}>
          Sell
        </Col>
        <Col xs={19} md={17}>
          <Space size={space}>
            <span>{(efficiency.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />)}&nbsp; L1</span>
            <span>{(efficiency.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />)}&nbsp; L2</span>
          </Space>
        </Col>
      </Row>

    </CardMetric>
  )
}

export default SignalsCard;