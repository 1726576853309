import '../styles/Home.css';

import { Layout } from 'antd';
import Arrival2 from './landings/pages/Arrival2';
import Candlestick from './landings/pages/Candlestick';
import Enrollement1 from './landings/pages/Enrollement1';
import Exchanges from './landings/pages/Exchanges';
import FAQ from './landings/pages/FAQ';
import FinancialCharts from './landings/pages/FinancialCharts';
import Portfolio from './landings/pages/Portfolio';
import Screener from './landings/pages/Screener';
import Testimonial from './landings/pages/Testimonial';
import CTA from './landings/pages/CTA';


// SHARED VARIABLES //

// lg is the breack point for iPad Pro 11 landscape
export const gutter = { xs: 0, sm: 0, md: 30, lg: 80, xxl: 90 }
export const IN_VIEW = 0.50
export const IN_VIEW_MOBILE = 0.5


const Home = () => {

    return (
        <Layout>
            <Arrival2 />
            <Enrollement1 />
            <Exchanges />
            <Candlestick />
            <Screener />
            <Portfolio />
            <FinancialCharts />
            <Testimonial />
            <CTA />
            <FAQ />
        </Layout>
    )
}

export default Home;