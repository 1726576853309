import { Button, Tooltip } from 'antd';
import React from "react";
import { COLOR_PRIMARY, TOOLTIP_ENTER_DELAY, TT_COLOR } from '../../JTIConst';
import { isMobile } from '../../utils/utils';

const IS_MOBILE = isMobile()

const ChartButton = ({ text, icon, onClick, active, disabled = false, ttText, ...props }) => {
    return (
        <Tooltip placement='top' color={TT_COLOR} title={ttText} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
            <Button
                disabled={disabled}
                color="default"
                variant="filled"
                style={{ color: active ? COLOR_PRIMARY : 'grey' }}
                icon={icon}
                onClick={onClick}
                {...props}>
                {IS_MOBILE ? '' : text}
            </Button>
        </Tooltip>
    );
};

export default ChartButton;