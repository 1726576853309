import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Card, Carousel, Col, Rate, Row } from 'antd';
import { useInView } from 'react-intersection-observer';
import { Tweet } from 'react-tweet';
import { COLOR_PRIMARY, GRID_GUTTER } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Testimonial.module.css';
import { isMobile } from '../../../utils/utils';
import { IN_VIEW, IN_VIEW_MOBILE } from '../../Home';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const Testimonial = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refObserver, inView] = useInView({
        triggerOnce: true, // Only trigger once when entering viewport

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefTestimonialsTitle = useSpringRef()
    const springTestimonialsTitle = useSpring({
        ref: springRefTestimonialsTitle,
        config: { duration: 600 },
        from: { opacity: 0, },
        to: {
            opacity: inView ? 1 : 0,
        },
    })

    const springRefTestimonials = useSpringRef()
    const springTestimonials = useSpring({
        ref: springRefTestimonials,
        config: { duration: 1000 },
        from: { opacity: inView ? 0 : 1, },
        to: {
            opacity: inView ? 1 : 0
        },
    })

    useChain([springRefTestimonialsTitle, springRefTestimonials], [0, 1], IS_MOBILE ? 1000 : 600)


    // CAROUSEL //

    const carouselData = [
        {
            author: 'Lisa - Sales Manager',
            text: 'I love how the earnings are summarized. No need to lose time reading boring reports—it’s all right there, simple.',
            rate: 5
        },
        {
            author: 'Fabien - Software Engineer',
            text: 'I’ve been using your algorithm for a little over a year now. I just reviewed my investments over the past year, and I made +50.8% 🙂 So, just a huge, huge thank you! I’m having a lot of fun investing by following JTI’s advice!',
            rate: 5,
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            author: 'Yannick - Software Architect',
            text: 'Justtradeit is a very good website for those who want to trade even without having a lot of knowledge on the subject. It is suitable for all levels with a very good interface. For my part, I started not very long ago and I reached up to 1 or 2% per month without really understanding what I was doing. Since I use justtradeit I have really increased this ratio. The site provides a lot of data that helps users make the right decisions!',
            rate: 5,
        },
        {
            author: 'Luc - Finance Enterprise Support',
            text: "JTI's buy/sell signals are what I use the most and so far so good :). So many other features I have yet to discover, can't wait to add more value on my future trades !",
            rate: 5
        },
        {
            author: 'Roger - Customer Success Manager',
            text: 'This platform has seriously saved me hours of research time. The two-click screener is a total game-changer—I can find good opportunities so quickly now.',
            rate: 5
        },
        {
            author: 'Lianet - Product Manager',
            text: 'This platform turns confusing data into something I can actually use. The AI charts give a lot insights and is easy to read.',
            rate: 5
        },
        {
            author: 'Steve - Digital Marketing',
            text: "As a newbie, I was lost in the investing world. This platform’s been my teacher—the summaries break things down, and the screener helps me find good picks. I’m actually enjoying learning now!",
            rate: 5
        },
        {
            tweetId: '1855413779703419200',
        },
        {
            author: 'John - Software Developer',
            text: "The buy and sell signals are a huge win for me. I was hesitant at first, but after following a few, I made some profitable trades I wouldn’t have spotted myself. Now I always look at them before making a trade.",
            rate: 5
        },
        {
            author: 'Damien - Manager',
            text: "My profits are up more than 20% since I started using this. The buy and sell signals are so helpful to not do mistakes. I also really like the stocks tagging system that saves a lot of time.",
            rate: 5
        },
        {
            author: 'Marc - Product Owner',
            text: "I’m new to investing, and this platform makes it way less intimidating. You are not too overwhelmed with informations as many website does. I look at it almost every to follow my investments.",
            rate: 5
        }
    ]

    // MOBILE
    var chunkSize = 6
    if (IS_MOBILE)
        chunkSize = 1

    // Group by chuncks
    const chunks = []
    for (let i = 0; i < carouselData.length; i += chunkSize) {
        chunks.push(carouselData.slice(i, i + chunkSize));
    }

    const carousel =
        <Carousel className={styles['carousel']} autoplay={true} autoplaySpeed={5000} speed={1100} dots={false} draggable={true}>
            {chunks.map((chunk, idx1) => (
                <div key={idx1}>
                    <Row gutter={[80, 10]} justify="space-around" align="middle">
                        {chunk.map((item, idx2) => (
                            <Col key={idx2} xs={24} sm={24} md={24} lg={8}>
                                <div className={styles['tweet']} data-theme="light">
                                    {
                                        item.tweetId ?
                                            <Tweet id={item.tweetId} />
                                            :
                                            <Card key={idx2} className="card" style={{ textAlign: "center" }}>
                                                <div className={styles['testimonial-author']}>
                                                    {item.author}
                                                </div>
                                                <div className={styles['testimonial-text']}>
                                                    "{item.text}"
                                                </div>
                                                <div className={styles['testimonial-rate']}>
                                                    <Rate value={item.rate} />
                                                </div>
                                            </Card>
                                    }
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Carousel>


    return (
        <div className="page" ref={refObserver}>
            <div className="landing-content">
                <animated.div className='page-title' style={springTestimonialsTitle}>
                    Investors Achieving <span style={{ color: COLOR_PRIMARY }}>Positive Returns</span>
                </animated.div>
                <animated.div style={springTestimonials}>
                    {carousel}
                </animated.div>
            </div>
        </div>
    )
}

export default Testimonial;