import { Layout, Typography, List, } from 'antd';
import PageTitle1 from '../components/PageTitle1';
import { JTI_EMAIL_CONTACT } from '../JTIConst';

const { Title, Paragraph, Text, Link } = Typography;
const { Item } = List;


const Privacy = () => {

    const titleSize = 3

    return (
        <Layout className="site-layout-content-fixed">
            <PageTitle1> • Privacy Policy</PageTitle1>

            <Paragraph><Text type="secondary">Last updated: August 29, 2023</Text></Paragraph>

            <br />
            <Paragraph>
                Thank you for choosing Just Trade It for better trading signals insights. At Just Trade It, we are committed to protecting your privacy.
                This Privacy Policy outlines how we collect, use, share, and protect your personal information. By using our website and services, you consent to the practices described in this policy.
            </Paragraph>

            <Title level={titleSize}>Information We Collect</Title>
            <Paragraph>
                We may collect the following types of information:
                <List style={{ margin: '5px 0 0 30px' }}>
                    <Item>
                        <Text strong>1. Personal Information: </Text>This includes your name, email address, and other contact information you provide when signing up for our services.
                    </Item>
                    <Item>
                        <Text strong>2. Payment Information: </Text>When you make payments on our platform, we may collect payment card details or other financial information. Rest assured, we use trusted payment processors, and your payment information is securely handled.
                    </Item>
                    <Item>
                        <Text strong>3. Usage Information: </Text>We collect information about how you use our website and services, such as your trading activity and interactions with our platform.
                    </Item>
                </List>
            </Paragraph>

            <Title level={titleSize}>How We Use Your Information</Title>
            <Paragraph>
                We use your information for the following purposes:
                <List style={{ margin: '5px 0 0 30px' }}>
                    <Item>
                        <Text strong>1. Providing Services: </Text>To deliver trading signals and related services to you.
                    </Item>
                    <Item>
                        <Text strong>2. Improving Services: </Text>To enhance and customize your experience on our platform.
                    </Item>
                    <Item>
                        <Text strong>3. Communication: </Text>To send you important updates, newsletters, and notifications related to our services.
                    </Item>
                </List>
            </Paragraph>

            <Title level={titleSize}>Information Sharing</Title>
            <Paragraph>
                We do not sell, trade, or share your personal information with third parties except as described in this policy.
                We may share your information with trusted partners and service providers who assist us in delivering our services.
            </Paragraph>

            <Title level={titleSize}>Data Security</Title>
            <Paragraph>
                We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. 
                While we take every precaution to ensure your data's safety, please be aware that no internet transmission method is entirely foolproof.
                Your trust is paramount to us, and we are committed to maintaining the best security practices.
            </Paragraph>

            <Title level={titleSize}>Your Choices</Title>
            <Paragraph>
                You have the right to:
                <List style={{ margin: '5px 0 0 30px' }}>
                    <Item>
                        1. Access, update, or correct your personal information by contacting us.
                    </Item>
                    <Item>
                        2. Opt-out of receiving marketing communications from us.
                    </Item>
                </List>
            </Paragraph>

            <Title level={titleSize}>Policy Updates</Title>
            <Paragraph>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for legal and regulatory reasons. Any updates will be posted on this page.
            </Paragraph>

            <Title level={titleSize}>Contact Us</Title>
            <Paragraph>
                If you have any questions or concerns about our Privacy Policy or your personal information, please contact us at <a href={`mailto:${JTI_EMAIL_CONTACT}`}>{JTI_EMAIL_CONTACT}</a>.
            </Paragraph>
            <Paragraph>
                Thank you for trusting Just Trade It. We are dedicated to safeguarding your privacy and providing you with valuable trading services.
            </Paragraph>
        </Layout>
    )
}

export default Privacy;