import { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Modal, Form, Input, Rate, Radio, } from 'antd';
import { createReview, createRequest } from '../../services/contact.service';


const { TextArea } = Input;


const ModalFeedback = (props) => {

    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [showTksMessage, setShowTksMessage] = useState(false);
    const tksMessage = "Thank you for your feedback! :)"

    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        }
    }


    const onFinish = async (values) => {
        setLoading(true)

        const reviewJson = {
            firstName: values.firstName,
            lastName: values.lastName,
            text: values.text,
            rate: values.rate
        }
        const accessToken = await getAccessTokenSilently()

        if (values.type === 'review')
            createReview(accessToken, reviewJson)
        else
            createRequest(accessToken, reviewJson)

        form.resetFields()
        setLoading(false)

        // Display tks message
        setShowTksMessage(true)
        setTimeout(() => {
            props.setOpen(false)
            setShowTksMessage(false)
        }, 3000)
    }

    const handleSubmit = () => {
        form.submit()
    }

    const handleCancel = () => {
        props.setOpen(false)
    }


    return (
        <Modal open={props.open} title="Write Review / Request" onCancel={handleCancel}
            footer={showTksMessage ? [] : [
                <Button key="submit" type="primary" onClick={handleSubmit} loading={loading}>
                    Submit
                </Button>
            ]}>

            {showTksMessage ? tksMessage :
                <Form form={form} name="review" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" {...layout} style={{ marginTop: "2em" }}>
                    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                        <Radio.Group defaultValue="review" size="small" buttonStyle="solid">
                            <Radio.Button value="review">Review</Radio.Button>
                            <Radio.Button value="request">Request</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="firstName" label="First Name" rules={[
                        {
                            required: true,
                            message: 'First Name is required',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="lastName" label="Last Name" rules={[
                        {
                            required: true,
                            message: 'Last Name is required',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Text" rules={[
                        {
                            required: true,
                            message: 'Review is required',
                        },
                    ]}>
                        <TextArea rows={4} placeholder="Write a review on JTI Platform, request for new symbols, features..." maxLength={1000} />
                    </Form.Item>
                    <Form.Item name="rate" label="Rate JTI"
                        rules={[
                            {
                                required: true,
                                message: 'Rate is required',
                            },
                        ]}>
                        <Rate />
                    </Form.Item>
                </Form>
            }
        </Modal>
    )
}

export default ModalFeedback;