import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Col, Image, Row, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';
import { SignupButton } from '../../../components/buttons/signup-button';
import { IconSignalBuyL2, IconSignalSellL2 } from '../../../components/Icons';
import { isMobile } from '../../../utils/utils';
import { gutter, IN_VIEW, IN_VIEW_MOBILE } from '../../Home';
import { TagAITagging } from '../../../components/Tags';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Screener = () => {

    // OBSERVER & SPRING EFFECTS //

    const [refP1, inViewP1] = useInView({
        triggerOnce: true,

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? IN_VIEW_MOBILE : IN_VIEW,
    })

    const springRefDescription = useSpringRef()
    const springDescription = useSpring({
        ref: springRefDescription,
        config: { duration: 400 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0, // Set opacity based on visibility
            transform: inViewP1 ? 'translateX(0)' : 'translateX(100px)'
        },
    })

    const springRefImage = useSpringRef()
    const springImage = useSpring({
        ref: springRefImage,
        config: { duration: 1000 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0
        },
    })

    useChain([springRefDescription, springRefImage], [0, 1], IS_MOBILE ? 1000 : 400)

    const text =
        <Col xs={24} md={8}>
            <animated.div className="text-container" style={{ ...springDescription, }}>
                <Title level={1} className='page-title-feature'>Find Opportunities In Few Clicks</Title>
                <Text className='page-description'>
                    The <b>AI Screener</b> helps you find the <b>best market opportunities</b> in just few steps with our <b>AI Signals</b> &nbsp;
                    <IconSignalBuyL2 /> <IconSignalSellL2 /> and <TagAITagging style={{ marginRight: '0' }} /> filters.<br />
                    Our AI Model continuously analyzes all assets, allowing you to focus only on the ones that matter.
                </Text>
                {!IS_MOBILE &&
                    <div>
                        <SignupButton className="landing-signup" type='primary' label='Explore AI Screener Free' />
                    </div>
                }
            </animated.div>
        </Col >
    const image =
        <Col xs={24} md={16} align='middle'>
            <animated.div className='img-background' style={{ ...springImage, }}>
                <Image src='/landing_screener.png' alt='Screener' preview={false} />
            </animated.div>
        </Col>


    return (
        <div className="page page-feature" ref={refP1}>
            <div className="landing-content">
                <Row gutter={gutter} justify="space-around" align="middle">
                    {IS_MOBILE ?
                        <>
                            {text}
                            {image}
                        </>
                        :
                        <>
                            {image}
                            {text}
                        </>
                    }
                </Row>
            </div>
        </div>
    )
}

export default Screener;