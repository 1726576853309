import { IconLoading } from './Icons';
import { Form, Input, Button, List, Typography, Divider, Select, Result, Checkbox, Space } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { subscribe, getDiscount } from "../services/payment.service";
import { iso31661 } from 'iso-3166'
import { JTI_EMAIL_CONTACT } from '../JTIConst';

const { Text, Link } = Typography;


// PARAMETERS //

const GST = 0.05
const QST = 0.09975

const SQUARE_APP_ID = process.env.REACT_APP_SQ_APP_ID;
const SQUARE_LOCATION_ID = process.env.REACT_APP_SQ_LOCATION_ID;
const PAYMENT_STATUS_INPROGRESS = 1
const PAYMENT_STATUS_LOADING = 2
const PAYMENT_STATUS_SUCCESS = 3
const PAYMENT_STATUS_FAILURE = 4


// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


const SubscriptionForm = ({ item, itemDesc, price, subscriptionPlan, catalogObjId, planVarId }) => {
    const cardRef = useRef(null);
    const cardContainerRef = useRef(null);
    const isLoadedRef = useRef(false);
    const { getAccessTokenSilently } = useAuth0();
    const [countryList, setCountryList] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS_INPROGRESS);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [paymentFormDisabled, setPaymentFormDisabled] = useState(false);
    const [displayDiscount, setDisplayDiscount] = useState(false);
    const [applyDiscountBtnLoading, setApplyDiscountBtnLoading] = useState(false);
    const [discountPercent, setDiscountPercent] = useState(0);


    // Define order items
    let orderItems = [
        {
            name: item,
            amount: price,
            description: itemDesc,
        },
    ]

    // Calcul taxes and total
    const initialSubtotal = orderItems.reduce((total, item) => total + item.amount, 0)
    const [subtotal, setSubtotal] = useState(initialSubtotal)
    const [qst, setQst] = useState(initialSubtotal * GST)
    const [gst, setGst] = useState(initialSubtotal * QST)
    const [total, setTotal] = useState(initialSubtotal + qst + gst)

    const calculateOrder = (pDiscountPercent) => {
        const newSubtotal = initialSubtotal - (initialSubtotal * pDiscountPercent / 100)
        const newGst = newSubtotal * GST
        const newQst = newSubtotal * QST
        setSubtotal(newSubtotal)
        setQst(newGst)
        setGst(newQst)
        setTotal(newSubtotal + newGst + newQst)
    }

    // Init card component
    async function initializeCard(payments) {
        const card = await payments.card();
        if (cardContainerRef.current !== null) {
            await card.attach(cardContainerRef.current);
        }
        return card;
    }

    // Apply the promo code
    const applyPromoCode = async () => {
        setApplyDiscountBtnLoading(true)
        const promoCode = document.querySelector('#promoInput').value

        const accessToken = await getAccessTokenSilently()
        const { data, error } = await getDiscount(accessToken, promoCode)

        if (data !== null) {
            setDisplayDiscount(true)
            setDiscountPercent(data.percent)
            calculateOrder(data.percent)
        } else {
            setDisplayDiscount(false)
            setDiscountPercent(0)
            calculateOrder(0)
        }

        setApplyDiscountBtnLoading(false)
    }

    const onSubmit = async (values) => {
        // console.debug("Form values: " + JSON.stringify(values))

        setPaymentStatus(PAYMENT_STATUS_LOADING)
        setPaymentFormDisabled(true)

        // disable the submit button as we await tokenization and make a payment request
        const submitButton = document.getElementById('submit-button')
        submitButton.disabled = true

        try {

            const tokenResult = await cardRef.current.tokenize();
            if (tokenResult.status === 'OK') {

                // TODO: verifyBuyer
                // const verificationToken = await verifyBuyer(payments, token);
                const verificationToken = null

                const subId = await executePayment(tokenResult.token, verificationToken, values)
                setPaymentStatus(PAYMENT_STATUS_SUCCESS)
                setSubscriptionId(subId)

            } else {

                let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                        tokenResult.errors
                    )}`;
                }

                console.debug(errorMessage)
                setPaymentStatus(PAYMENT_STATUS_INPROGRESS)
                submitButton.disabled = false
                setPaymentFormDisabled(false)

            }
        } catch (e) {
            submitButton.disabled = false
            setPaymentFormDisabled(false)
            setPaymentStatus(PAYMENT_STATUS_FAILURE)
            console.error(e)
        }

        // Currently, not needed
        // cardRef.current.clear()
    }

    async function executePayment(cardToken, verificationToken, values) {

        const accessToken = await getAccessTokenSilently();
        const req = {
            subscriptionPlan: subscriptionPlan,
            locationId: SQUARE_LOCATION_ID,
            catalogObjectId: catalogObjId,
            planVariationId: planVarId,
            sourceId: cardToken,
            promoCode: document.querySelector('#promoInput').value,
            billingAddress: {
                addressLine1: values.address1,
                addressLine2: values.address2,
                locality: values.city,
                country: values.country,
                postalCode: values.postalCode,
                firstName: values.firstName,
                lastName: values.lastName
            }
        };
        const { data, error } = await subscribe(accessToken, req)

        if (error)
            throw new Error('Error during payment processing')
        else
            return data
    }

    // Required in SCA Mandated Regions: Learn more at https://developer.squareup.com/docs/sca-overview
    // async function verifyBuyer(payments, token) {
    //     const verificationDetails = {
    //         amount: '1.00',
    //         billingContact: {
    //             addressLines: ['123 Main Street', 'Apartment 1'],
    //             familyName: 'Doe',
    //             givenName: 'John',
    //             email: 'jondoe@gmail.com',
    //             country: 'GB',
    //             phone: '3214563987',
    //             region: 'LND',
    //             city: 'London',
    //         },
    //         currencyCode: 'GBP',
    //         intent: 'CHARGE',
    //     };

    //     const verificationResults = await payments.verifyBuyer(
    //         token,
    //         verificationDetails
    //     );
    //     return verificationResults.token;
    // }


    // EFFECTS //

    useEffect(() => {
        const fetchData = async () => {

            if (!window.Square) {
                throw new Error('Square.js failed to load properly');
            }

            let payments;
            try {
                payments = window.Square.payments(SQUARE_APP_ID, SQUARE_LOCATION_ID);
            } catch {
                // TODO : Handle Square card component init error
                return;
            }

            try {
                cardRef.current = await initializeCard(payments);
            } catch (e) {
                console.error('Initializing Card failed', e);
                return;
            }

            // Create country list
            setCountryList(iso31661.map(o => {
                return {
                    value: o.alpha2,
                    label: o.name
                }
            }))
        }

        // Avoid infinit card component loading
        if (isLoadedRef.current) return;
        isLoadedRef.current = true;

        fetchData();
    })


    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }

    return (<>
        <div>
            <List split={false} itemLayout="horizontal" dataSource={orderItems} renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta title={
                        <div className='order-summary-line'>
                            <div>
                                <Text strong>{item.name}</Text>
                            </div>
                            <div>
                                <Text strong>{`$${item.amount.toFixed(2)}`}</Text>
                            </div>
                        </div>}
                        description={item.description} />
                </List.Item>)} />

            <Divider style={{ margin: '8px 0' }} />

            {displayDiscount && <>
                <div className='order-summary-line'>
                    <Text strong>Discount {discountPercent}%</Text>
                    <Text strong>{`$-${(initialSubtotal * discountPercent / 100).toFixed(2)}`}</Text>
                </div>
                <Divider style={{ margin: '8px 0' }} />
            </>}

            <div className='order-summary-line'>
                <Text type="secondary">Subtotal</Text>
                <Text type="secondary">{`$${subtotal.toFixed(2)}`}</Text>
            </div>
            <div className='order-summary-line'>
                <Text type="secondary">GST (5%)</Text>
                <Text type="secondary">{`$${qst.toFixed(2)}`}</Text>
            </div>
            <div className='order-summary-line'>
                <Text type="secondary">QST (9.975%)</Text>
                <Text type="secondary">{`$${gst.toFixed(2)}`}</Text>
            </div>
            <div className='order-summary-line' style={{ marginTop: '20px' }}>
                <Text>TOTAL</Text>
                <Text>{`$${total.toFixed(2)}`}</Text>
            </div>
        </div>

        {paymentStatus === PAYMENT_STATUS_SUCCESS ?
            <Result status="success"
                title="Congrats on taking another step toward your financial independence!"
                subTitle={<>Subscription ref. #{subscriptionId}</>}
                extra={[
                    <Button type="primary" href="/">Start Using New Features</Button>
                ]} />
            :
            <>
                <div style={{ display: 'flex', justifyContent: 'right', width: '100%', marginTop: '2em' }}>
                    <Space><Input id="promoInput" placeholder="PROMO CODE" /> <Button onClick={applyPromoCode} loading={applyDiscountBtnLoading} iconPosition='end'>Apply Code</Button></Space>
                </div>
                <Form {...formLayout} id="payment-form" onFinish={onSubmit} style={{ marginTop: '50px' }} layout="horizontal" disabled={paymentFormDisabled}>
                    <div style={{ marginBottom: '15px' }}>
                        <Text strong underline>Billed to</Text>
                    </div>
                    <Form.Item label="First Name" name="firstName" labelAlign="right" rules={[{ required: true }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Last name" name="lastName" labelAlign="right" rules={[{ required: true }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Country" name="country" labelAlign="right" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            placeholder="Select a country"
                            filterOption={filterOption}
                            options={countryList}
                        />
                    </Form.Item>
                    <Form.Item label="Address line 1" name="address1" rules={[{ required: true }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Address line 2" name="address2" rules={[{ required: false }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Postal code" name="postalCode" rules={[{ required: true }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="City" name="city" rules={[{ required: true }]} style={{ marginBottom: '3em' }}>
                        <Input placeholder="" />
                    </Form.Item>

                    <div ref={cardContainerRef} />

                    <Form.Item name="terms" valuePropName="checked" style={{ marginBottom: '2em', textAlign: 'center' }} wrapperCol={24} rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Accepting terms and conditions is required')),
                        },
                    ]}>
                        <Checkbox>I have read and agree to the <Link href="/terms" target="_blank">terms and conditions</Link></Checkbox>
                    </Form.Item>

                    <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                        {
                            paymentStatus === PAYMENT_STATUS_INPROGRESS || paymentStatus === PAYMENT_STATUS_FAILURE ? <Button id='submit-button' type="primary" htmlType="submit">Upgrade Now</Button> :
                                paymentStatus === PAYMENT_STATUS_LOADING && <IconLoading fontSize={30} />
                        }
                        {
                            paymentStatus === PAYMENT_STATUS_FAILURE &&
                            <div style={{ marginTop: '1.5em' }}>
                                Payment error: ensure your billing postal code matches your credit card's. If issues persist, contact us at {JTI_EMAIL_CONTACT} for assistance.
                            </div>
                        }
                    </div>
                </Form>
            </>
        }
    </>)
}

export default SubscriptionForm;