import { Layout } from 'antd';
import React, { useState } from "react";
import LeftMenu from '../../components/LeftMenu.jsx';
import Indices from './Indices.jsx';
import FinancialGraph from './FinancialGraph.jsx';
import MarketsCalendar from './MarketsCalendar.jsx';
import MetaTags from '../../MetaTags.js';
import { useParams } from 'react-router-dom';

const { Content } = Layout;


const Markets = () => {

    const { paramMenuKey } = useParams();

    const defaultMenuKey = 'indices'

    const components = {
        'indices': <Indices />,
        'calendar': <MarketsCalendar />,
        'financial-graph': <FinancialGraph />
    }
    const [selectedComponent, setSelectedComponent] = useState(paramMenuKey ? components[paramMenuKey] : components[defaultMenuKey]);

    const menuItems = [
        { key: "indices", label: "Indices" },
        { key: "calendar", label: "Calendar" },
        { key: "financial-graph", label: "Financial Graph" },
    ]

    const handleSelectedMenu = (key) => {
        window.history.replaceState(null, '', '/markets/' + key);
        setSelectedComponent(components[key]);
    }

    return (
        <Layout className="site-layout-content">
            <MetaTags title="Just Trade It: Macro View" />

            <LeftMenu defaultKey={paramMenuKey ? paramMenuKey : defaultMenuKey} menuItems={menuItems} handleSelectedMenu={handleSelectedMenu} />

            <Content>
                {selectedComponent}
            </Content>
        </Layout>
    )
}

export default Markets;