import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { sendEmail } from '../../services/jti.service';
import PageTitle1 from "../../components/PageTitle1";

const { Option } = Select;


// PARAMETERS //


const SendEmail = () => {

    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0()

    const onFinish = async (values) => {
        setLoading(true);

        const accessToken = await getAccessTokenSilently();
        await sendEmail(accessToken, {
            mode: values.mode,
            to: values.email,
            subject: values.subject,
            htmlBody: values.htmlBody,
            attachmentsFolder: values.attachmentsFolder
        })

        setLoading(false);
    }

    return (<>
        <PageTitle1>• Send Email</PageTitle1>

        <Card className='card'>
            <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Recipient Email"
                    name="email"
                    rules={[{ required: true, type: "email", message: "Please enter a valid email" }]}
                >
                    <Input placeholder="Enter recipient email" />
                </Form.Item>

                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Please enter a subject" }]}
                >
                    <Input placeholder="Enter subject" />
                </Form.Item>

                <Form.Item
                    label="HTML Email Body"
                    name="htmlBody"
                    rules={[{ required: true, message: "Please enter the email content" }]}
                >
                    <Input.TextArea rows={4} placeholder="Enter HTML email content" />
                </Form.Item>

                <Form.Item
                    label="Attachments Folder (ex.: /Users/mike/it/git/react-email-starter/emails/static/newsletter-20250216)"
                    name="attachmentsFolder"
                >
                    <Input placeholder="Enter email attachments folder" />
                </Form.Item>

                <Form.Item
                    label="Mode"
                    name="mode"
                    rules={[{ required: true, message: "Please select a mode" }]}
                >
                    <Select placeholder="Select mode">
                        <Option value="ONE_RECIPIENT">ONE_RECIPIENT</Option>
                        <Option value="ALL_NEWSLETTER">ALL_NEWSLETTER</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {loading ? "Sending..." : "Send Email"}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    </>)
}

export default SendEmail