import { Button, Input, Layout, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BlogPostNavigationBottom from '../components/BlogPostNavigationBottom';
import BlogTitle1 from '../components/BlogTitle1';
import MyBreadcrumb from '../components/Breadcrumb';
import { SignupButton } from '../components/buttons/signup-button';
import DynamicComponent from '../components/DynamicComponent';
import PageTitle2 from '../components/PageTitle2';
import PageTitle3 from '../components/PageTitle3';
import MetaTags from '../MetaTags';
import { getPost } from '../services/blog.service';
import '../styles/Blog.css';
import { BLOG_CRUMB, blogPosts } from './Blog';
import CommonStocksVSPreferredStocks from './blog/CommonStocksVSPreferredStocks';
import DiversifiedStockPortfolio from './blog/DiversifiedStockPortfolio';
import FinancialStatementAnalysis from './blog/FinancialStatementAnalysis';
import IndexFunds from './blog/IndexFunds';
import PriceToBookRatio from './blog/PriceToBookRatio';
import PriceToEarningsRatio from './blog/PriceToEarningsRatio';
import RatiosTerms from './blog/RatiosTerms';
import StockMarketInvesting from './blog/StockMarketInvesting';
import SwingResilientStrategy from './blog/SwingResilientStrategy';
import TechnicalAnalysisStockMarket from './blog/TechnicalAnalysisStockMarket';
import TheBasicsofDividendInvesting from './blog/TheBasicsofDividendInvesting';
import Top10GrowthStocks2025 from './blog/Top10GrowthStocks2025';
import TopInvestingBooks from './blog/TopInvestingBooks';
import TradeChecklist from './blog/TradingChecklist';
import Error from './Error';
import { formatDateForBlog } from '../utils/utils';
import { Tweet } from 'react-tweet';


const { Paragraph } = Typography;


const components = {
    [blogPosts[0].path]: <SwingResilientStrategy title={blogPosts[0].title} path={blogPosts[0].path} updatedDate={blogPosts[0].updatedDate} />,
    [blogPosts[1].path]: <TradeChecklist title={blogPosts[1].title} path={blogPosts[1].path} updatedDate={blogPosts[1].updatedDate} />,
    [blogPosts[2].path]: <CommonStocksVSPreferredStocks title={blogPosts[2].title} path={blogPosts[2].path} updatedDate={blogPosts[2].updatedDate} />,
    [blogPosts[3].path]: <TheBasicsofDividendInvesting title={blogPosts[3].title} path={blogPosts[3].path} updatedDate={blogPosts[3].updatedDate} />,
    [blogPosts[4].path]: <Top10GrowthStocks2025 title={blogPosts[4].title} path={blogPosts[4].path} updatedDate={blogPosts[4].updatedDate} />,
    [blogPosts[5].path]: <StockMarketInvesting title={blogPosts[5].title} path={blogPosts[5].path} updatedDate={blogPosts[5].updatedDate} />,
    [blogPosts[6].path]: <RatiosTerms title={blogPosts[6].title} path={blogPosts[6].path} updatedDate={blogPosts[6].updatedDate} />,
    [blogPosts[7].path]: <PriceToEarningsRatio title={blogPosts[7].title} path={blogPosts[7].path} updatedDate={blogPosts[7].updatedDate} />,
    [blogPosts[8].path]: <IndexFunds title={blogPosts[8].title} path={blogPosts[8].path} updatedDate={blogPosts[8].updatedDate} />,
    [blogPosts[9].path]: <DiversifiedStockPortfolio title={blogPosts[9].title} path={blogPosts[9].path} updatedDate={blogPosts[9].updatedDate} />,
    [blogPosts[10].path]: <TopInvestingBooks title={blogPosts[10].title} path={blogPosts[10].path} updatedDate={blogPosts[10].updatedDate} />,
    [blogPosts[11].path]: <TechnicalAnalysisStockMarket title={blogPosts[11].title} path={blogPosts[11].path} updatedDate={blogPosts[11].updatedDate} />,
    [blogPosts[12].path]: <PriceToBookRatio title={blogPosts[12].title} path={blogPosts[12].path} updatedDate={blogPosts[12].updatedDate} />,
    [blogPosts[13].path]: <FinancialStatementAnalysis title={blogPosts[13].title} path={blogPosts[13].path} updatedDate={blogPosts[13].updatedDate} />,
}

//
const DynamicBlogPost = ({ title, metaDescription, metaKeywords, path, imgAlt, updatedDate, content, scope, cta }) => (
    <Layout className="site-layout-content-fixed site-layout-content-blog">
        <MetaTags
            title={title}
            description={metaDescription}
            keywords={metaKeywords}
        />

        <MyBreadcrumb items={BLOG_CRUMB.concat([
            {
                title: title,
            }
        ])} />

        <BlogTitle1>
            • {title}
            <div className='blog-title-meta'>{formatDateForBlog(updatedDate)}</div>
        </BlogTitle1>

        <Paragraph className='blog-paragraph'>
            <img width="100%" src={`/blog/${path}.jpg`} alt={imgAlt} />
        </Paragraph>

        <DynamicComponent code={content} scope={scope} />

        <SignupButton className="blog-cta" type='primary' label={cta} />

        <BlogPostNavigationBottom />
    </Layout>
)

const scope = { Button, Input, BlogTitle1, PageTitle2, PageTitle3, Paragraph, Tweet };

const BlogPost = () => {

    const { urlPath } = useParams();
    const [blogComponent, setBlogComponent] = useState(components[urlPath]);

    
    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await getPost(urlPath)

            if (error)
                setBlogComponent(<Error />)
            else
                setBlogComponent(<DynamicBlogPost
                    title={data.title}
                    metaDescription={data.metaDescription}
                    metaKeywords={data.metaKeywords}
                    path={data.path}
                    imgAlt={data.imgAlt}
                    updatedDate={data.updatedDate}
                    content={data.content}
                    scope={scope}
                    cta={data.cta} />)
        }

        if (!blogComponent) {
            fetchData()
        }
    }, [])


    return blogComponent
}

export default BlogPost;