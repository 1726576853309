import { getInstanceByDom, init } from 'echarts';
import React, { useEffect, useRef, useState } from "react";
import '../../styles/IndexesView.css';
import { COLOR_GREEN, COLOR_GREEN_LIGHT, COLOR_ORANGE, COLOR_RED, WIN_RATE_LIMIT_AVG, WIN_RATE_LIMIT_GOOD, WIN_RATE_LIMIT_HIGH } from '../../JTIConst';
import { isMobile } from '../../utils/utils';


// PARAMETERS //


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const Gauge = ({ width, height, percent, name }) => {

    const chartRef = useRef(null);
    const [options, setOptions] = useState({});
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        setLoading(true);

        // Set chart options
        setOptions({
            series: [
                {
                    type: "gauge",
                    startAngle: 180,
                    endAngle: 0,
                    center: [IS_MOBILE ? "42%" : "47%", "81%"],
                    radius: "90%",
                    min: 0,
                    max: 1,
                    splitNumber: 20,
                    axisLine: {
                        lineStyle: {
                            width: 0,
                            color: [
                                [WIN_RATE_LIMIT_AVG / 100, COLOR_RED],
                                [WIN_RATE_LIMIT_GOOD / 100, COLOR_ORANGE],
                                [WIN_RATE_LIMIT_HIGH / 100, COLOR_GREEN_LIGHT],
                                [1, COLOR_GREEN],
                            ],
                        },
                    },
                    pointer: {
                        icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                        length: "10%",
                        width: 9,
                        offsetCenter: [0, IS_MOBILE ? "-87%" : "-100%"],
                        itemStyle: { color: "auto" },
                    },
                    axisTick: {
                        show: false,
                        // distance: -10,
                        // length: 10, 
                        // lineStyle: { color: "auto", width: 1 }
                    },
                    splitLine: {
                        distance: IS_MOBILE ? -15 : -20,
                        length: 12,
                        lineStyle: { color: "auto", width: 3 }
                    },
                    axisLabel: {
                        color: "auto",
                        fontSize: 14,
                        distance: -28,
                        rotate: "tangential",
                        formatter: (value) => {
                            if (value === 0.95) return "High";
                            if (value === 0.80) return "Good";
                            if (value === 0.65) return "Avg";
                            if (value === 0.30) return "Low";
                            return "";
                        },
                    },
                    title: { offsetCenter: [0, "-0%"], fontSize: 14 },
                    detail: {
                        fontWeight: "normal",
                        fontSize: 25,
                        offsetCenter: [0, "-10%"],
                        valueAnimation: true,
                        formatter: (value) => Math.round(value * 100) + "%",
                        color: "inherit",
                    },
                    data: [{ value: percent }],
                },
            ],
        });

        setLoading(false);

    }, [percent, name]);

    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading() : chart.hideLoading();
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [loading]);                              // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(options);
        }
    }, [options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    return <div ref={chartRef} style={{ width: width, height: height, margin: 'auto' }} />;
};

export default Gauge;
