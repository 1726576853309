import { Image, Layout, Typography } from 'antd';
import React from 'react';
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';
import BlogTitle1 from '../../components/BlogTitle1';
import PageTitle2 from '../../components/PageTitle2';
import '../../styles/Blog.css';
import MetaTags from '../../MetaTags';
import { BLOG_CRUMB, blogPosts } from '../Blog';
import MyBreadcrumb from '../../components/Breadcrumb';
import { SignupButton } from '../../components/buttons/signup-button';
import PageTitle3 from '../../components/PageTitle3';
import BlogList from '../../components/blog/BlogList';


const { Paragraph } = Typography;

const relatedPosts = [4, 9, 10]

const TheBasicsofDividendInvesting = ({ title, path, updatedDate }) => {
    return (
        <Layout className="site-layout-content-fixed site-layout-content-blog">
            <MetaTags
                title="The Basics of Dividend Investing: Build Wealth with Passive Income"
                description="Learn how dividend investing helps create a steady passive income stream. Discover strategies, benefits, and tips for building a diversified dividend portfolio."
                keywords="dividend investing, passive income, dividend stocks, dividend portfolio, DRIP, dividend growth, financial independence, dividend reinvestment" />

            <MyBreadcrumb items={BLOG_CRUMB.concat([
                {
                    title: title,
                }
            ])} />

            <BlogTitle1>
                • {title}
                <div className='blog-title-meta'>{updatedDate}</div>
            </BlogTitle1>

            <Paragraph className='blog-paragraph'>
                <Image width="100%" src={`/blog/${path}.jpg`} alt="Chart showing consistent dividend payouts" />
            </Paragraph>

            <Paragraph className="blog-paragraph">
                Dividend investing has long been regarded as one of the most effective ways to build wealth steadily while receiving passive income. By investing in dividend-paying stocks, investors can generate consistent returns without having to sell their holdings. In this blog, we’ll explore the basics of dividend investing, the benefits it offers, and how you can start building your passive income stream.
            </Paragraph>

            <PageTitle2>What Is Dividend Investing?</PageTitle2>
            <Paragraph className="blog-paragraph">
                Dividend investing involves purchasing stocks of companies that regularly pay dividends to their shareholders. These dividends represent a portion of the company’s earnings distributed to stockholders, typically on a quarterly basis.
            </Paragraph>
            <Paragraph className="blog-paragraph">
                Companies that pay dividends are often well-established, profitable businesses with a history of steady earnings. Examples of popular dividend-paying companies in the U.S. include Johnson & Johnson (JNJ), Coca-Cola (KO), and Procter & Gamble (PG).
            </Paragraph>

            <PageTitle2>Why Invest in Dividends?</PageTitle2>
            <ul className="blog-list">
                <li>
                    <b>Passive Income:</b> Dividends provide regular income, often reinvested to compound wealth over time.
                    <br />
                    <i>Example:</i> Investing in Coca-Cola shares, which pay dividends every quarter, allows you to earn money while holding the stock.
                </li>
                <li>
                    <b>Lower Risk:</b> Dividend-paying stocks tend to be more stable and less volatile, as they represent mature companies.
                    <br />
                    <i>Example:</i> Procter & Gamble (PG), a household goods company, is known for its stability and consistent dividends, even during market downturns.
                </li>
                <li>
                    <b>Reinvestment Opportunities:</b> Dividends can be reinvested to buy more shares, accelerating the growth of your investment portfolio through the power of compounding.
                    <br />
                    <i>Example:</i> Reinvesting dividends from stocks like McDonald's (MCD) can help you accumulate more shares, leading to increased future dividend payouts.
                </li>
            </ul>

            <PageTitle2>How to Build a Dividend Portfolio</PageTitle2>
            <ul className="blog-list">
                <li>
                    <b>Research Dividend Stocks:</b> Look for companies with a history of consistent dividend payments and strong fundamentals. Tools like Dividend Aristocrats (companies that have raised dividends for 25 consecutive years) can guide your search.
                </li>
                <li>
                    <b>Diversify Your Portfolio:</b> Invest in stocks from different sectors to reduce risk. Consider industries such as utilities, consumer staples, and healthcare, which are known for steady dividends.
                    <br />
                    <i>Example:</i> A portfolio of Johnson & Johnson (JNJ), Coca-Cola (KO), and AT&T (T) can provide you with diversified exposure to dividend-paying stocks.
                </li>
                <li>
                    <b>Check the Dividend Yield:</b> The dividend yield is the annual dividend payment divided by the stock price. A higher yield is appealing but ensure the company can sustain the dividend over time.
                    <br />
                    <i>Example:</i> AT&T (T) offers a high dividend yield, making it attractive for income-focused investors, but assess the company’s ability to maintain that dividend.
                </li>
                <li>
                    <b>Consider Dividend Growth:</b> Focus on companies that not only pay dividends but also have a history of increasing their dividend payments over time.
                    <br />
                    <i>Example:</i> Johnson & Johnson (JNJ) is known for raising its dividend year after year, making it a solid choice for long-term dividend growth.
                </li>
            </ul>

            <PageTitle2>The Power of Dividend Reinvestment (DRIP)</PageTitle2>
            <Paragraph className="blog-paragraph">
                Dividend Reinvestment Plans (DRIPs) allow investors to automatically reinvest their dividends into additional shares of stock, often without paying commissions or fees. This compounding effect can significantly boost the growth of your investment over time.
            </Paragraph>

            <PageTitle2>Risks of Dividend Investing</PageTitle2>
            <ul className="blog-list">
                <li>
                    <b>Dividend Cuts:</b> Companies may reduce or eliminate their dividend payouts due to financial difficulties.
                    <br />
                    <i>Example:</i> During the 2020 market downturn, several companies temporarily suspended their dividends due to the impact of the COVID-19 pandemic.
                </li>
                <li>
                    <b>Interest Rate Sensitivity:</b> Dividend-paying stocks can be affected by rising interest rates, as they become less attractive compared to bonds.
                    <br />
                    <i>Example:</i> If interest rates rise, stocks like Verizon (VZ) may offer less attractive yields, leading investors to shift to bonds.
                </li>
                <li>
                    <b>Company-Specific Risks:</b> Even stable companies may face unforeseen challenges that affect dividend payments.
                    <br />
                    <i>Example:</i> If a company faces a significant drop in revenue, it may cut its dividend to conserve cash.
                </li>
            </ul>

            <PageTitle2>Real-World Example: Investing in Dividend Stocks</PageTitle2>
            <Paragraph className="blog-paragraph">
                Consider an investor who buys Coca-Cola (KO) shares at $50 per share. If Coca-Cola pays a quarterly dividend of $0.40 per share, the investor would earn $1.60 per year for every share owned. Over time, by reinvesting these dividends, the investor’s shares will grow, leading to higher dividend payments and increased capital appreciation.
            </Paragraph>

            <PageTitle2>Conclusion</PageTitle2>
            <Paragraph className="blog-paragraph">
                Dividend investing is an effective strategy for generating passive income, reducing portfolio volatility, and achieving long-term financial goals. By carefully selecting dividend-paying stocks, diversifying your portfolio, and reinvesting dividends, you can build a solid foundation for steady income.
            </Paragraph>
            <Paragraph className="blog-paragraph">
                Ready to start your dividend investing journey? Begin researching solid dividend-paying companies like Coca-Cola, Procter & Gamble, or Johnson & Johnson, and set up a plan to build your wealth through dividends today.
            </Paragraph>

            <SignupButton className="blog-cta" type='primary' label='Create Passive Income Today' />

            <PageTitle3>Related Blog Posts</PageTitle3>
            <BlogList blogs={blogPosts.filter((_, index) => relatedPosts.includes(index))} />

            <BlogPostNavigationBottom />
        </Layout>
    );
};

export default TheBasicsofDividendInvesting;