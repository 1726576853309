import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Col, Row, Space } from 'antd';
import { COLOR_GREEN, COLOR_RED, GRID_GUTTER_S } from '../JTIConst';
import { getAITag, isMobile } from '../utils/utils';
import { TagClassResilient, TagClassYoung, TagFinStrong, TagFinWeak } from './Tags';


const IS_MOBILE = isMobile()


const TickerTags = ({ tkrInfos, efficiency, tourRef }) => {

    // Define interval
    var efficiency = tkrInfos.oneDay
    // if (props.interval == 'hourly') {
    //     efficiency = tkrInfos.oneHour
    // }

    // Define tags
    const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient light={true} /> : null;
    const tagYoung = tkrInfos.tags?.young ? <TagClassYoung light={true} /> : null;

    const tagFinancialStrong = tkrInfos.tags?.financialStrong ? <TagFinStrong light={true} /> : null;
    const tagFinancialWeak = tkrInfos.tags?.financialWeak ? <TagFinWeak light={true} /> : null;

    var modelEfficiencyGood = ''
    var modelEfficiencyWarn = ''
    if (efficiency.total_win_percent >= 70)
        modelEfficiencyGood = getAITag(efficiency.total_win_percent, true);
    else
        modelEfficiencyWarn = getAITag(efficiency.total_win_percent, true);

    // Define goodTags and warnTags with fallback values
    const goodTags = modelEfficiencyGood || tagResilient || tagFinancialStrong ? (
        <>
            {modelEfficiencyGood}
            {tagResilient}
            {tagFinancialStrong}
        </>
    ) : "-";

    const warnTags = modelEfficiencyWarn || tagYoung || tagFinancialWeak ? (
        <>
            {modelEfficiencyWarn}
            {tagYoung}
            {tagFinancialWeak}
        </>
    ) : "-";

    const iconStyle = { fontSize: "1.3em", marginRight: "0.8em" }

    return (
        <Row id='tags' ref={tourRef} gutter={GRID_GUTTER_S} className='grid-bottom-s'>
            <Col xs={24} md={12}>
                <Card className='card-filled'>
                    <CheckCircleOutlined style={{ color: COLOR_GREEN, ...iconStyle }} />
                    <Space wrap>{goodTags}</Space>
                </Card>
            </Col>
            <Col xs={24} md={12}>
                <Card className='card-filled'>
                    <WarningOutlined style={{ color: COLOR_RED, ...iconStyle }} />
                    <Space wrap>{warnTags}</Space>
                </Card>
            </Col>
        </Row>
    )
}

export default TickerTags;