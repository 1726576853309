import { Statistic } from "antd";
import Range from "../charts/Range";
import { IconInfoMedianSwing } from "../Icons";
import CardMetric from "./CardMetric";
import CountUp from 'react-countup';

const formatter = (value) => <CountUp className='card-metric-countup' end={value} decimals={1} separator="." />;

const SwingTimeCard = ({ minValue, maxValue, currentValue }) => {
  return (
    <CardMetric title='Median Holding Period' extra={<IconInfoMedianSwing />}>
      {/* <Range width={'80%'} height={100} minValue={minValue} maxValue={maxValue} currentValue={currentValue} /> */}
      <Statistic title="Days" value={currentValue} formatter={formatter} />
    </CardMetric>
  )
}

export default SwingTimeCard;