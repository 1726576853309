import { notification, Skeleton, Card } from 'antd';
import React, { useContext, useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { init, getInstanceByDom } from 'echarts';
import { IconInfoFinancialGraph } from '../../components/Icons';
import { getFinancialGraph } from "../../services/marketAnalysis.service";
import UserContext from '../../components/UserContext';
import { NotifUpgradePlan } from '../../components/Notifications';
import { isAllowedToViewFinancialGraph } from "../../services/authorization.service";
import { UnauthorizedFinancialGraph } from '../../components/TempLang';
import PageTitle1 from '../../components/PageTitle1';


const FinancialGraph = () => {

    const { userContext } = useContext(UserContext);
    const { getAccessTokenSilently } = useAuth0();

    const chartRef = useRef(null);
    const [chartLoading, setChartLoading] = useState(true);
    const [chartOptions, setChartOptions] = useState({});

    // Notification
    const [notifApi, contextHolder] = notification.useNotification();


    useEffect(() => {
        const fetchData = async () => {

            setChartLoading(true);

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getFinancialGraph(accessToken);
            // console.log("Object:" + JSON.stringify(data));

            const nodes = data.nodes
            nodes.forEach(function (node) {
                node.label = {
                    show: node.symbolSize > 0
                };
            });

            const links = data.links
            links.forEach(function (l) {
                l.label = {
                    show: true,
                    formatter: '{c}'
                };
            });
            // console.log("Object:" + JSON.stringify(links));

            const categoriesMoreKeys = [
                { "itemStyle": { "color": "Orange" } },
                { "itemStyle": { "color": "Green" } }
            ];

            // Add styles to the categories
            const categories = data.categories.map((item, index) => {
                return Object.assign({}, item, categoriesMoreKeys[index]);
            });

            // Chart Options ------------------------------------------------------------------------------------------------------------------------

            setChartOptions({
                title: {
                    text: 'Powered by Just Trade It',
                    top: 'bottom',
                    left: 'right'
                },
                tooltip: {},
                legend: {
                    left: 'right',
                    orient: 'vertical',
                    align: 'left',
                    data: categories.map(function (c) {
                        return c.name;
                    })
                },
                animationDuration: 3000,
                animationDurationUpdate: 3000,
                animationEasingUpdate: 'quinticInOut',
                series: [
                    {
                        type: 'graph',
                        legendHoverLink: false,
                        layout: 'circular',
                        circular: {
                            rotateLabel: true
                        },
                        edgeSymbol: ['none', 'arrow'],
                        edgeSymbolSize: 11,
                        data: nodes,
                        links: links,
                        categories: categories,
                        roam: true,
                        label: {
                            position: 'right',
                            formatter: '{b}',
                            width: 68,
                            overflow: 'break'
                        },
                        lineStyle: {
                            color: 'source',
                            curveness: 0.3
                        },
                        emphasis: {
                            focus: 'adjacency',
                            lineStyle: {
                                width: 4
                            },
                            edgeLabel: {
                                show: true
                            },
                            label: {
                                show: true
                            },
                        },
                        // blur: {
                        //     lineStyle: {
                        //         opacity: 0
                        //     }
                        // }
                    }
                ]
            })

            setChartLoading(false);
        }

        // MARK SEC - Financial Graph
        if (isAllowedToViewFinancialGraph(userContext)) {
            fetchData()
        } else {
            notifApi.info(NotifUpgradePlan(UnauthorizedFinancialGraph))
        }

    }, [])

    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
        }

        // Add chart resize listener, ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [chartLoading]);    // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(chartOptions);
        }
    }, [chartOptions]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            chartLoading === true ? chart.showLoading() : chart.hideLoading();
        }
    }, [chartLoading]);


    return (<>
        <PageTitle1> • Financial Relationship Graph<IconInfoFinancialGraph /></PageTitle1>

        <Card className='card'>
            {isAllowedToViewFinancialGraph(userContext) ? (
                <div id='income-statements' ref={chartRef} className='jti-charts' style={{ height: "860px" }} />
            ) : (
                <Skeleton paragraph={{ rows: 4 }} />
            )}
        </Card>

        {contextHolder}
    </>)
}

export default FinancialGraph;
