import { volcano } from '@ant-design/colors';
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { COLOR_GREEN, COLOR_GREEN_LIGHT, COLOR_ORANGE, COLOR_PRIMARY, TT_COLOR, WIN_RATE_LIMIT_AVG, WIN_RATE_LIMIT_GOOD, WIN_RATE_LIMIT_HIGH } from '../JTIConst';
import { InfoTagAIHoldingStatusHold, InfoTagFinancialStrong, InfoTagFinancialWeak, InfoTagResilient, InfoTagWinRate, InfoTagWinRateLowest, InfoTagYoung } from './TempLang';

const COLOR_RED = volcano[6]

export const TagAITagging = (props) => {
    return <Tag color={COLOR_GREEN} className="tag" style={{ ...props.style }}>AI Tagging System</Tag>;
}

// Asset Classification

export const TagClassResilient = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagResilient />}>
            <Tag color={COLOR_GREEN} className="tag">{!light && <CheckOutlined />} Resilient</Tag>
        </Tooltip>)
}

export const TagClassYoung = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagYoung />}>
            <Tag color={COLOR_ORANGE} className="tag">{!light && <WarningOutlined />} Young</Tag>
        </Tooltip>)
}

// Financials

export const TagFinStrong = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagFinancialStrong />}>
            <Tag color={COLOR_GREEN} className="tag">{!light && <CheckOutlined />} Strong Financial</Tag>
        </Tooltip>)
}

export const TagFinWeak = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagFinancialWeak />}>
            <Tag color={COLOR_ORANGE} className="tag">{!light && <WarningOutlined />} Weak Financial</Tag>
        </Tooltip>)
}

// AI model efficiency

export const TagAIHigh = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={InfoTagWinRate(WIN_RATE_LIMIT_HIGH)}>
            <Tag color={COLOR_GREEN} className="tag">{!light && <CheckOutlined />} Win Rate High</Tag>
        </Tooltip>)
}

export const TagAIGood = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={InfoTagWinRate(WIN_RATE_LIMIT_GOOD)}>
            <Tag color={COLOR_GREEN_LIGHT} className="tag">{!light && <CheckOutlined />} Win Rate Good</Tag>
        </Tooltip>)
}

export const TagAIAverage = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={InfoTagWinRate(WIN_RATE_LIMIT_AVG)}>
            <Tag color={COLOR_ORANGE} className="tag">{!light && <WarningOutlined />} Win Rate Avg</Tag>
        </Tooltip>)
}

export const TagAILow = ({light = false}) => {
    return (
        <Tooltip color={TT_COLOR} title={InfoTagWinRateLowest(WIN_RATE_LIMIT_AVG)}>
            <Tag color={COLOR_RED} className="tag">{!light && <WarningOutlined />} Win Rate Low</Tag>
        </Tooltip>)
}

// AI holding status

export const TagAIHoldingStatusHold = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagAIHoldingStatusHold />}>
            {/* <Tag color={COLOR_GREEN} className="tag"><Icon component={hodor} style={{ color: '#fff' }} /> Hodor</Tag> */}
            <Tag color={COLOR_GREEN} className="tag">Hold</Tag>
        </Tooltip>)
}

// Market Trend

export const TagTrendBear = () => {
    return <Tag icon={<ArrowDownOutlined />} color={COLOR_RED} className="tag">Bear Market</Tag>
}

export const TagTrendBull = () => {
    return <Tag icon={<ArrowUpOutlined />} color={COLOR_GREEN} className="tag">Bull Market</Tag>
}

// Risk NOTUSED

export const TagRiskL1 = () => {
    return <Tag color={COLOR_GREEN} className="tag">Risk -</Tag>;
}

export const TagRiskL2 = () => {
    return <Tag color={COLOR_GREEN} className="tag">Risk +</Tag>;
}

export const TagRiskL3 = () => {
    return <Tag color={COLOR_GREEN} className="tag">Risk ++</Tag>;
}


// Terms

export const TagShortTerm = () => {
    return <Tag color={COLOR_GREEN} className="tag">Short Term</Tag>;
}

export const TagMediumTerm = () => {
    return <Tag color={COLOR_ORANGE} className="tag">Medium Term</Tag>;
}

export const TagLongTerm = () => {
    return <Tag color={COLOR_RED} className="tag">Long Term</Tag>;
}


// Charts

export const TagSupport = () => {
    return <Tag color="grey" className="tag"><CheckOutlined /> Support</Tag>;
}

export const TagResistance = () => {
    return <Tag color="grey" className="tag"><CheckOutlined /> Resistance</Tag>;
}

// Refresh chart to see changes
const chartTTTagStyle = { fontWeight: '520', letterSpacing: '1px', lineHeight: '1.6em', padding: '0.1rem 0.4rem', display: 'inline-block', borderRadius: '4px', color: '#fff' }
export const TagSupportTT = () => {
    return <Tag color={COLOR_PRIMARY} style={chartTTTagStyle} bordered={false}><CheckOutlined /> Support</Tag>;
}

export const TagResistanceTT = () => {
    return <Tag color={COLOR_PRIMARY} style={chartTTTagStyle} bordered={false}><CheckOutlined /> Resistance</Tag>;
}