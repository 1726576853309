import { Layout } from 'antd';
import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import LeftMenu from '../../components/LeftMenu.jsx';
import AdminHome from './AdminHome.js';
import AuditTrail from './AuditTrail.js';
import Community from './Community.js';
import Test from './Test.js';
import Users from './Users.js';
import SendEmail from './SendEmail.js';
import AddBlogPost from './AddBlogPost.js';

const { Content } = Layout;


const Admin = () => {

    const { paramMenuKey } = useParams();

    const components = {
        'home': <AdminHome />,
        'audit-trail': <AuditTrail />,
        'users': <Users />,
        'community': <Community />,
        'send-email': <SendEmail />,
        'add-blog-post': <AddBlogPost />,
        'test': <Test />,
    }
    const [selectedComponent, setSelectedComponent] = useState(paramMenuKey ? components[paramMenuKey] : components['home']);

    const menuItems = [
        { key: "home", label: "Home" },
        { key: "audit-trail", label: "Audit Trail" },
        { key: "users", label: "Users" },
        { key: "community", label: "Community" },
        { key: "send-email", label: "Send Email" },
        { key: "add-blog-post", label: "Add Blog Post" },
        { key: "test", label: "Test" },
    ]

    const handleSelectedMenu = (key) => {
        window.history.replaceState(null, '', '/admin/' + key);
        setSelectedComponent(components[key]);
    }

    return (
        <Layout className="site-layout-content">
            <LeftMenu defaultKey={paramMenuKey ? paramMenuKey : 'home'} menuItems={menuItems} handleSelectedMenu={handleSelectedMenu} />

            <Content>
                {selectedComponent}
            </Content>
        </Layout>
    )
}

export default Admin;