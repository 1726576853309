import { useAuth0 } from "@auth0/auth0-react";
import { Calendar, Card, Col, Image, Layout, Row, Select, Space, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from "react";
import UserContext from '../../components/UserContext';
import { DATE_YEAR_FIRST, TT_COLOR } from '../../JTIConst';
import { getEarnings } from "../../services/ticker.service";
import { displayData, formatNumberToM, getLogoUrl, navigateToTicker } from '../../utils/utils';
import { IconInfoCalendar, IconLoading } from '../../components/Icons';
import PageTitle1 from '../../components/PageTitle1';


const currYear = new Date().getFullYear()


const MarketsCalendar = () => {

    const { userContext } = useContext(UserContext);
    const { getAccessTokenSilently } = useAuth0();
    const [earnings, setEarnings] = useState([]);

    // Calendar cell render
    const dateCellRender = (value) => {
        let listTkr = new Map()
        const listData = earnings[value.format(DATE_YEAR_FIRST)]
        if (listData)
            listTkr = listData

        const logoList = Object.entries(listTkr).map(([k, v]) => (
            <Col>
                <Tooltip key={k} color={TT_COLOR} title={(<>
                    <div><b>{k}</b></div>
                    <div>EARNINGS</div>
                    <div>Estimate: {v.epsEstimate}</div>
                    <div>Reported: {displayData(v.epsReported, '')}</div>
                    <div>Surprise: {displayData(parseFloat(v.epsSurprise).toFixed(2), '')}%</div>
                    <div>REVENUE</div>
                    <div>Estimate: {formatNumberToM(v.revenueEstimate)}</div>
                    <div>Reported: {displayData(formatNumberToM(v.revenueReported), '')}</div>
                    <div>Surprise: {displayData(parseFloat(v.revenueSurprise).toFixed(2), '')}%</div>
                </>)}>
                    <Image src={getLogoUrl(k)} width={22} preview={false}
                        onClick={(e) => navigateToTicker(e, k)} onAuxClick={(e) => navigateToTicker(e, k)} />
                </Tooltip>
            </Col>
        ))

        return <Row gutter={[14, 8]}>{logoList}</Row>
    }
    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    }


    // EFFECTS //

    useEffect(() => {
        const fetchData = async () => {

            if (userContext != null) {
                const accessToken = await getAccessTokenSilently()
                const resp = await getEarnings(accessToken)
                setEarnings(resp.data)
            }
        }
        fetchData()
    }, [userContext])


    if (userContext === null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>
        )
    }

    return (<>

        <PageTitle1> • Earnings Calendar<IconInfoCalendar /></PageTitle1>

        <Card className='Card'>
            <Calendar cellRender={cellRender} headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];
                let current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.monthsShort(current));
                }
                for (let i = start; i < end; i++) {
                    monthOptions.push(
                        <Select.Option key={i} value={i} className="month-item">
                            {months[i]}
                        </Select.Option>,
                    );
                }

                const year = value.year();
                const month = value.month();
                const options = [];
                for (let i = currYear - 1; i <= currYear + 1; i += 1) {
                    options.push(
                        <Select.Option key={i} value={i} className="year-item">
                            {i}
                        </Select.Option>,
                    );
                }

                return (
                    <div style={{
                        textAlign: 'right',
                        width: '100%',
                        margin: '1.6em 0'
                    }}>
                        <Space>
                            <Select
                                value={month}
                                onChange={(newMonth) => {
                                    const now = value.clone().month(newMonth);
                                    onChange(now);
                                }}>
                                {monthOptions}
                            </Select>
                            <Select
                                className="my-year-select"
                                value={year}
                                onChange={(newYear) => {
                                    const now = value.clone().year(newYear);
                                    onChange(now);
                                }}>
                                {options}
                            </Select>
                        </Space>
                    </div>
                )
            }} />
        </Card>
    </>)
}

export default MarketsCalendar;