import { Layout, Typography, } from 'antd';
import PageTitle1 from '../components/PageTitle1';
import { JTI_EMAIL_CONTACT } from '../JTIConst';

const { Title, Paragraph, Text, Link } = Typography;


const Terms = () => {

    const titleSize = 4

    return (
        <Layout className="site-layout-content-fixed">
            <PageTitle1> • Terms of Use</PageTitle1>
            <Paragraph><Text type="secondary">Last updated: May 10, 2024</Text></Paragraph>

            <br />
            <Paragraph>
                Welcome to Just Trade It! By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions.
                Please read these Terms of Use carefully before using our website. If you do not agree to these terms, please do not use our services.
            </Paragraph>

            <Title level={titleSize}>1. Acceptance of Terms</Title>
            <Paragraph>
                By accessing or using Just Trade It, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.
                If you do not agree to these terms, you should not use our services.
            </Paragraph>

            <Title level={titleSize}>2. Informational Purposes Only</Title>
            <Paragraph>
                Just Trade It is an online platform that provides stock trading signals and information for educational and informational purposes only.
                The signals and information provided on the website are not intended as financial advice or recommendations for trading.
                We do not endorse, recommend, or guarantee any specific stocks or trading strategies.
            </Paragraph>

            <Title level={titleSize}>3. No Accountability for Trading Decisions</Title>
            <Paragraph>
                While we strive to provide accurate and up-to-date information, Just Trade It is not responsible for any trading decisions made by users based on the signals and information provided on the website.
                Users acknowledge that all trading involves risk and that they are solely responsible for their trading decisions and their outcomes.
            </Paragraph>

            <Title level={titleSize}>4. No Financial or Legal Advice</Title>
            <Paragraph>
                The information provided on Just Trade It is not intended to be financial, investment, or legal advice.
                Users should consult with a qualified financial advisor or legal professional before making any trading decisions or taking any action based on the information provided on our website.
            </Paragraph>

            <Title level={titleSize}>5. Accuracy of Information</Title>
            <Paragraph>
                We make efforts to provide accurate and reliable information, but we do not guarantee the accuracy, completeness, or timeliness of the information provided on our website.
                Users should verify any information before making trading decisions.</Paragraph>

            <Title level={titleSize}>6. Risk Disclaimer</Title>
            <Paragraph>
                Trading stocks involves a high level of risk, and past performance is not indicative of future results.
                Users should be aware of the risks associated with trading and should only trade with funds they can afford to lose.
                Just Trade It is not liable for any losses or damages incurred as a result of trading based on our signals or information.</Paragraph>

            <Title level={titleSize}>7. User Responsibility</Title>
            <Paragraph>
                Users are solely responsible for their actions and decisions while using our services.
                This includes understanding and managing the risks associated with trading and taking appropriate precautions.
            </Paragraph>

            <Title level={titleSize}>8. Indemnification</Title>
            <Paragraph>
                By using Just Trade It, you agree to indemnify and hold us harmless from any claims, losses, liabilities,
                and expenses (including legal fees) arising out of or related to your use of our services, violation of these Terms of Use, or violation of any rights of a third party.
            </Paragraph>

            <Title level={titleSize}>9. Subscription Plans</Title>
            <Paragraph>
                Just Trade It offers subscription plans for access to premium features and services. 
                By subscribing to a plan, you acknowledge and agree to abide by the terms outlined in these Terms of Use. 
                All subscription plans are billed on a recurring basis according to the selected payment cycle (monthly, quarterly, or annually).
            </Paragraph>

            <Title level={titleSize}>10. Cancellation Policy</Title>
            <Paragraph>
                Users may cancel their subscription plan at any time by accessing their account settings on the Just Trade It website. 
                Upon cancellation, the subscription will remain active until the end of the current billing cycle, and no further charges will be incurred. 
                Please note that there are no refunds for partial subscription periods.
            </Paragraph>

            <Title level={titleSize}>11. End of Plan</Title>
            <Paragraph>
                Upon cancellation of a subscription plan, access to premium features and services will cease at the end of the current billing cycle. 
                Users will no longer be able to access subscription-only content or features unless they renew their subscription.
            </Paragraph>

            <Title level={titleSize}>12. Changes to Terms</Title>
            <Paragraph>
                Just Trade It reserves the right to modify, update, or change these Terms of Use at any time without prior notice. 
                It is your responsibility to review these terms periodically for any changes.
            </Paragraph>

            <Title level={titleSize}>13. Governing Law</Title>
            <Paragraph>
                These Terms of Use shall be governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.
            </Paragraph>

            <Title level={titleSize}>14. Contact Us</Title>
            <Paragraph>
                If you have any questions about these Terms of Use, please contact us at <a href={`mailto:${JTI_EMAIL_CONTACT}`}>{JTI_EMAIL_CONTACT}</a>.
            </Paragraph>

            <br />
            <Paragraph>By using Just Trade It, you acknowledge that you have read, understood, and agreed to these Terms of Use. If you do not agree with these terms, please do not use our services. Your use of our website and services constitutes your acceptance of these terms.</Paragraph>
        </Layout>
    )
}

export default Terms;