import React, { useEffect, useState } from 'react';
import { Image, Button, Input, Typography } from 'antd'; // Import Ant Design components
import DynamicComponent from '../../components/DynamicComponent'; // Your DynamicComponent implementation
import 'antd/dist/reset.css'; // Import Ant Design CSS for styling
import BlogTitle1 from '../../components/BlogTitle1';
import PageTitle2 from '../../components/PageTitle2';
import PageTitle3 from '../../components/PageTitle3';
import '../../styles/Blog.css';

const title = "toto"
const img = "http://localhost:3000/blog/swing-resilient-strategy.jpg"
const { Paragraph } = Typography;

const Test = () => {
  const [dbCode, setDbCode] = useState('');

  // Variables to insert
  const title = "toto";
  const img = "http://localhost:3000/blog/swing-resilient-strategy.jpg";

  // Simulate fetching dbCode from a REST API
  useEffect(() => {
    // Replace this with your actual REST API call
    const fetchDbCode = async () => {
      try {
        // Simulated API response
        const response = `
          () => {
            const [value, setValue] = React.useState('');
            return (
              <div>
                <BlogTitle1>{{title}}</BlogTitle1>
                <Paragraph className='blog-paragraph'>
                  <img width="100%" src="{{img}}" alt="Stock market graph showcasing growth potential of common and preferred stocks" />
                </Paragraph>
                <Paragraph className='blog-paragraph'>
                  When it comes to stock market investing, understanding the different types of stocks available is crucial. Two of the most common types are common stocks and preferred stocks. While both represent ownership in a company, they come with different rights, benefits, and risks. In this blog, we’ll compare the two and help you decide which one suits your investment strategy.
                </Paragraph>
                <PageTitle2>What Are Common Stocks?</PageTitle2>
                <Paragraph className='blog-paragraph'>
                  Common stocks are the most widely traded type of stock, and owning them means you have a stake in a company’s success. When you buy common stocks, you become a partial owner of the company and can benefit from price appreciation and dividends.
                </Paragraph>
                <ul className='blog-list'>
                  <li><b>Voting Rights:</b> Common shareholders have voting rights, typically on issues like the election of board members.</li>
                  <li><b>Dividends:</b> Common shareholders may receive dividends, but they’re not guaranteed.</li>
                  <li><b>Higher Risk, Higher Reward:</b> Common stockholders are last in line for claims in case of liquidation.</li>
                </ul>
              </div>
            );
          }
        `;
        
        // Replace placeholders with actual variables
        const populatedCode = response
          .replace('{{title}}', title)
          .replace('{{img}}', img);

        setDbCode(populatedCode);
      } catch (error) {
        console.error('Error fetching dbCode:', error);
      }
    };

    fetchDbCode();
  }, [title, img]); // Re-run if title or img changes

  // Scope with components and variables
  const scope = { Button, Input, BlogTitle1, PageTitle2, PageTitle3, Paragraph};

  return (
    <div>
      <h1>Dynamic Component with Ant Design</h1>
      {dbCode ? (
        <DynamicComponent code={dbCode} scope={scope} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Test;