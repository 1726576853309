import { AimOutlined, FullscreenOutlined, GatewayOutlined, ReloadOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Space, Switch, Tooltip } from "antd";
import { useContext, useEffect, useState } from 'react';
import { TOOLTIP_ENTER_DELAY, TT_COLOR } from "../../JTIConst";
import { isMobile } from '../../utils/utils';
import { FullScreen, Measure, PriceTargets, Reset, ShowSuppResis } from "../TempLang";
import UserContext from '../UserContext';
import ChartButton from '../buttons/ChartButton';


const IS_MOBILE = isMobile()


const CandlestickChartToolbox = ({ chartRef, tkrInfos, fullScreen, ...props }) => {

    const { userContext } = useContext(UserContext);

    const [stateBtnPriceTargets, setStateBtnPriceTargets] = useState(false)
    const [showBtnPriceTargets, setShowBtnPriceTargets] = useState(true)
    const [stateBtnSuppResis, setStateBtnSuppResis] = useState(false)
    const [stateBtnBrush, setStateBtnBrush] = useState(false)
    const [chartStyleSwitch, setChartStyleSwitch] = useState(false)

    const togglePriceTargets = () => {
        chartRef.current?.togglePriceTargets()
        setStateBtnPriceTargets(prevValue => !prevValue)
    }

    const toggleSuppResis = () => {
        chartRef.current?.toggleSuppResis()
        setStateBtnSuppResis(prevValue => !prevValue)
    }

    const toggleBrush = () => {
        chartRef.current?.toggleBrush()
        setStateBtnBrush(prevValue => !prevValue)
    }

    const toggleZoomReset = (checked) => {
        chartRef.current?.toggleZoomReset()
        resetChartButtons()
    }

    const updChartMode = (checked) => {
        chartRef.current?.updChartMode(checked)
        setChartStyleSwitch(checked)
        resetChartButtons()
    }

    const resetChartButtons = () => {
        // Disable chart buttons
        setStateBtnSuppResis(false)
        setStateBtnBrush(false)
        setStateBtnPriceTargets(false)
    }


    useEffect(() => {
        let candleSwitch = userContext?.chartMode == "PRO"
        setChartStyleSwitch(candleSwitch)

        setShowBtnPriceTargets(tkrInfos?.analyst?.priceTargets !== undefined)
    }, [tkrInfos])


    return (
        <Space {...props}>

            {/* Price targets */}
            <ChartButton
                text='Price Target'
                ttText={PriceTargets}
                disabled={!showBtnPriceTargets}
                active={stateBtnPriceTargets}
                icon={<AimOutlined />}
                onClick={togglePriceTargets} />

            {/* Support & Resistance */}
            <ChartButton
                text='Support & Resistance'
                ttText={ShowSuppResis}
                active={stateBtnSuppResis}
                icon={<VerticalAlignMiddleOutlined />}
                onClick={toggleSuppResis} />

            {!IS_MOBILE &&
                <Tooltip placement='top' color={TT_COLOR} title={Measure} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                    <ChartButton
                        text='Mesure'
                        ttText={Measure}
                        active={stateBtnBrush}
                        icon={<GatewayOutlined />}
                        onClick={toggleBrush} />
                </Tooltip>
            }

            <ChartButton
                text='Reset'
                ttText={Reset}
                icon={<ReloadOutlined />}
                onClick={toggleZoomReset} />

            <ChartButton
                id="btn-candle-full-screen"
                ttText={FullScreen}
                icon={<FullscreenOutlined />}
                onClick={fullScreen} />

            <Space>Light <Switch checked={chartStyleSwitch} onChange={updChartMode} /> Pro</Space>

        </Space>
    )
}

export default CandlestickChartToolbox;